import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserInfo,
  getSubscribedSports,
  checkSportPermissions,
  getSportData,
} from '../../api/subscriptionsApi';

// ** Async Thunks **

/**
 * Thunk to fetch user profile information.
 */
export const fetchUserInfo = createAsyncThunk(
  'subscription/fetchUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      return await getUserInfo();
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch user info.');
    }
  },
);

/**
 * Thunk to fetch subscribed sports for the user.
 */
export const fetchSubscribedSports = createAsyncThunk(
  'subscription/fetchSubscribedSports',
  async (_, { rejectWithValue }) => {
    try {
      return await getSubscribedSports();
    } catch (error) {
      return rejectWithValue(
        error.message || 'Failed to fetch subscribed sports.',
      );
    }
  },
);

/**
 * Thunk to check sport permissions.
 */
export const fetchSportPermissions = createAsyncThunk(
  'subscription/fetchSportPermissions',
  async (sportId, { rejectWithValue }) => {
    try {
      return await checkSportPermissions(sportId);
    } catch (error) {
      return rejectWithValue(
        error.message || 'Failed to check sport permissions.',
      );
    }
  },
);

/**
 * Thunk to fetch sport data including games.
 */
export const fetchSportData = createAsyncThunk(
  'subscription/fetchSportData',
  async ({ sportId, includeTomorrow }, { rejectWithValue }) => {
    try {
      return await getSportData(sportId, includeTomorrow);
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch sport data.');
    }
  },
);

// ** Initial State **
const initialState = {
  userInfo: null,
  subscribedSports: [],
  sportPermissions: {},
  sportData: [],
  isLoading: false,
  error: null,
};

// ** Slice **
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    /**
     * Clear user state (useful for logout).
     */
    clearUserState(state) {
      state.userInfo = null;
      state.subscribedSports = [];
      state.sportPermissions = {};
      state.sportData = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch user info
      .addCase(fetchUserInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle fetch subscribed sports
      .addCase(fetchSubscribedSports.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSubscribedSports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscribedSports = action.payload;
      })
      .addCase(fetchSubscribedSports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle fetch sport permissions
      .addCase(fetchSportPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSportPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sportPermissions = action.payload;
      })
      .addCase(fetchSportPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle fetch sport data
      .addCase(fetchSportData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSportData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sportData = action.payload;
      })
      .addCase(fetchSportData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// ** Export Actions and Reducer **
export const { clearUserState } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
