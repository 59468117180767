import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/NBAPlayerDAvg.css';
import LoadingSpinner from '../../common/LoadingSpinner';

const NBAPlayerDAvgReport = () => {
  const [playerData, setPlayerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [opposingTeamList, setOpposingTeamList] = useState([]);
  const [selectedOpposingTeam, setSelectedOpposingTeam] = useState('');
  const [playerNameFilter, setPlayerNameFilter] = useState('');
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [visibleColumns, setVisibleColumns] = useState({
    opposingTeam: true,
    avgPointsAllowed: true,
    avgAssistsAllowed: true,
    avgReboundsAllowed: true,
    avg3PMAllowed: true,
    avgStealsAllowed: true,
    avgBlocksAllowed: true,
    avgTurnoversAllowed: true,
    playerList: true,
  });
  const [showFilteredPlayers, setShowFilteredPlayers] = useState(false);
  const [minimizedRows, setMinimizedRows] = useState(new Set());

  const navigate = useNavigate();

  // Fetch player defensive average report data and player-team map
  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/player-d-avg-report/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(response.data.nba_player_average_report)) {
          setPlayerData(response.data.nba_player_average_report);
          setFilteredData(response.data.nba_player_average_report);

          // Extract unique opposing teams for the dropdown
          const uniqueOpposingTeams = [
            ...new Set(
              response.data.nba_player_average_report.map(
                (item) => item.opposing_team,
              ),
            ),
          ];
          setOpposingTeamList(uniqueOpposingTeams);
        } else {
          setPlayerData([]);
          setFilteredData([]);
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching player defensive averages.');
        setLoading(false);
      }
    };

    const fetchTeamData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const teamResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/player-team-map/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(teamResponse.data.nba_player_teams)) {
          setTeamList(teamResponse.data.nba_player_teams);
        } else {
          setTeamList([]);
        }
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchPlayerData();
    fetchTeamData();
  }, []);

  // Handle filtering the data by opposing team, player name, and selected team
  const handleFilterChange = () => {
    let filtered = playerData;

    // Filter by selected opposing team
    if (selectedOpposingTeam) {
      filtered = filtered.filter(
        (item) =>
          item.opposing_team.toLowerCase() ===
          selectedOpposingTeam.toLowerCase(),
      );
    }

    // Filter by player name (case-insensitive search)
    const trimmedPlayerFilter = playerNameFilter.trim().toLowerCase();
    if (trimmedPlayerFilter) {
      filtered = filtered.filter((item) =>
        item.player_name.toLowerCase().includes(trimmedPlayerFilter),
      );
    }

    // Filter by selected team
    if (selectedTeam) {
      const playersInSelectedTeam = teamList
        .filter((teamPlayer) => teamPlayer.team_name === selectedTeam)
        .map((teamPlayer) => teamPlayer.player_name.toLowerCase());

      filtered = filtered.filter((item) =>
        playersInSelectedTeam.some((player) =>
          item.player_name.toLowerCase().includes(player),
        ),
      );
    }

    setFilteredData(filtered);
  };

  // Toggle column visibility
  const toggleColumn = (column) => {
    setVisibleColumns({
      ...visibleColumns,
      [column]: !visibleColumns[column],
    });
  };

  // Toggle between showing all players or filtered players
  const handleTogglePlayerView = () => {
    setShowFilteredPlayers(!showFilteredPlayers);
  };

  // Helper function to filter players based on the selected team
  const getFilteredPlayerList = (playerList) => {
    if (!selectedTeam) {
      return playerList;
    }

    const playersInSelectedTeam = teamList
      .filter((teamPlayer) => teamPlayer.team_name === selectedTeam)
      .map((teamPlayer) => teamPlayer.player_name.toLowerCase());

    return playerList
      .split(', ')
      .filter((player) => playersInSelectedTeam.includes(player.toLowerCase()))
      .join(', ');
  };

  // Toggle row visibility (minimize/expand)
  const toggleRowVisibility = (index) => {
    const newMinimizedRows = new Set(minimizedRows);
    if (minimizedRows.has(index)) {
      newMinimizedRows.delete(index);
    } else {
      newMinimizedRows.add(index);
    }
    setMinimizedRows(newMinimizedRows);
  };

  // Reset minimized rows
  const resetRows = () => {
    setMinimizedRows(new Set());
  };

  if (loading) {
    return (
      <LoadingSpinner text="Loading NBA Player vs. Defense Average Report..." />
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="player-d-avg-report-container">
      <h1>NBA Player Defensive Averages Report</h1>

      {/* Explanatory text for defensive averages */}
      <p className="info-text">
        This report displays defensive statistics for individual players against
        specific opposing teams. First 10 days of season will be statistics from
        previous year.
      </p>

      <div className="filter-container">
        {/* Dropdown for Opposing Team */}
        <label htmlFor="opposingTeamFilter">Filter by Opposing Team: </label>
        <select
          id="opposingTeamFilter"
          value={selectedOpposingTeam}
          onChange={(e) => setSelectedOpposingTeam(e.target.value)}
        >
          <option value="">Select a Team</option>
          {opposingTeamList.map((team, index) => (
            <option key={index} value={team}>
              {team}
            </option>
          ))}
        </select>

        <label htmlFor="playerNameFilter">Filter by Player Name: </label>
        <input
          type="text"
          id="playerNameFilter"
          value={playerNameFilter}
          onChange={(e) => setPlayerNameFilter(e.target.value)}
          placeholder="Enter player name"
        />

        <label htmlFor="teamFilter">Filter by Offensive Team: </label>
        <select
          id="teamFilter"
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value)}
        >
          <option value="">Select a Team</option>
          {[...new Set(teamList.map((player) => player.team_name))].map(
            (team, index) => (
              <option key={index} value={team}>
                {team}
              </option>
            ),
          )}
        </select>

        <button onClick={handleFilterChange} className="filter-button">
          Search
        </button>
      </div>

      {/* Toggle between showing all players or filtered players */}
      <div className="player-view-toggle">
        <label>
          <input
            type="checkbox"
            checked={showFilteredPlayers}
            onChange={handleTogglePlayerView}
          />
          Show only players from the selected offensive team
        </label>
      </div>
      <br />

      {/* Column toggles */}
      <div className="column-toggle-container">
        {Object.keys(visibleColumns).map((col) => (
          <label key={col}>
            <input
              type="checkbox"
              checked={visibleColumns[col]}
              onChange={() => toggleColumn(col)}
            />
            {col
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, (str) => str.toUpperCase())}{' '}
            {/* Formatting column names */}
          </label>
        ))}
      </div>

      {/* Reset Rows Button */}
      <div className="reset-button-container">
        <button className="reset-button" onClick={resetRows}>
          Reset Grid
        </button>
      </div>

      {/* Back Button */}
      <div className="back-button-container">
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>

      <table className="player-d-avg-report-table">
        <thead>
          <tr>
            {visibleColumns.opposingTeam && <th>Opposing Team</th>}
            {visibleColumns.avgPointsAllowed && <th>Avg Points Allowed</th>}
            {visibleColumns.avgAssistsAllowed && <th>Avg Assists Allowed</th>}
            {visibleColumns.avgReboundsAllowed && <th>Avg Rebounds Allowed</th>}
            {visibleColumns.avg3PMAllowed && <th>Avg 3PM Allowed</th>}
            {visibleColumns.avgStealsAllowed && <th>Avg Steals Allowed</th>}
            {visibleColumns.avgBlocksAllowed && <th>Avg Blocks Allowed</th>}
            {visibleColumns.avgTurnoversAllowed && (
              <th>Avg Turnovers Allowed</th>
            )}
            {visibleColumns.playerList && <th>Player List</th>}
            <th>Action</th> {/* Column for Minimize/Expand */}
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((metric, index) => (
              <tr key={index}>
                {minimizedRows.has(index) ? (
                  <td colSpan="12">
                    {' '}
                    {/* Minimized row with button to expand */}
                    <button onClick={() => toggleRowVisibility(index)}>
                      +
                    </button>{' '}
                    {/* Expand button */}
                  </td>
                ) : (
                  <>
                    {visibleColumns.opposingTeam && (
                      <td>{metric.opposing_team}</td>
                    )}
                    {visibleColumns.avgPointsAllowed && (
                      <td>{metric.avg_points}</td>
                    )}
                    {visibleColumns.avgAssistsAllowed && (
                      <td>{metric.avg_assists}</td>
                    )}
                    {visibleColumns.avgReboundsAllowed && (
                      <td>{metric.avg_rebounds}</td>
                    )}
                    {visibleColumns.avg3PMAllowed && <td>{metric.avg_3pm}</td>}
                    {visibleColumns.avgStealsAllowed && (
                      <td>{metric.avg_steals}</td>
                    )}
                    {visibleColumns.avgBlocksAllowed && (
                      <td>{metric.avg_blocks}</td>
                    )}
                    {visibleColumns.avgTurnoversAllowed && (
                      <td>{metric.avg_turnovers}</td>
                    )}
                    {visibleColumns.playerList && (
                      <td>
                        {showFilteredPlayers
                          ? getFilteredPlayerList(metric.player_name)
                          : metric.player_name}
                      </td>
                    )}
                    <td>
                      <button onClick={() => toggleRowVisibility(index)}>
                        -
                      </button>{' '}
                      {/* Minimize button */}
                    </td>
                  </>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NBAPlayerDAvgReport;
