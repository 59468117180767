import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPitcherCheatSheetData } from '../../../redux/slices/mlbSlice';
import LoadingSpinner from '../../common/LoadingSpinner';

const PitcherCheatSheet = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pitcherData = useSelector((state) => state.mlb.pitcherCheatSheet);
  const error = useSelector((state) => state.mlb.error);
  const isLoading = useSelector((state) => state.mlb.isLoading);
  const [expandedTeams, setExpandedTeams] = useState({});

  useEffect(() => {
    if (date) {
      dispatch(fetchPitcherCheatSheetData(date));
    }
  }, [date, dispatch]);

  const toggleTeamExpansion = (team) => {
    setExpandedTeams((prev) => ({
      ...prev,
      [team]: !prev[team],
    }));
  };

  const calculatePredictedStrikeouts = (strikeoutRate, inningsPitched) => {
    if (!strikeoutRate) {
      return 'Not enough data';
    }
    return (((strikeoutRate * 27) / 9) * inningsPitched).toFixed(2);
  };

  const calculatePredictedHits = (battingAverage, inningsPitched) => {
    if (!battingAverage) {
      return 'Not enough data';
    }
    return (((battingAverage * 27) / 9) * inningsPitched).toFixed(2);
  };

  const generateVegasLine = ({
    strikeouts_over_under,
    earned_runs_over_under,
    hits_allowed_over_under,
    walks_issued_over_under,
  }) => {
    const lines = [
      { metric: 'Strikeouts', value: strikeouts_over_under },
      { metric: 'Earned Runs', value: earned_runs_over_under },
      { metric: 'Hits Allowed', value: hits_allowed_over_under },
      { metric: 'Walks Issued', value: walks_issued_over_under },
    ];

    return lines
      .filter((line) => line.value !== null)
      .map((line) => `• ${line.metric}: O/U ${line.value}`)
      .join('\n');
  };

  const generateWriteup = (pitcher) => {
    const {
      predicted_innings_pitched,
      predicted_strikeouts_thrown,
      predicted_earned_runs,
      predicted_hits_allowed,
      predicted_walks_issued,
      opposing_team_average_runs_per_game_over_5_innings,
      opposing_team_average_runs_per_game_over_6_innings,
      opposing_team_season_batting_average,
      opposing_team_strikeout_rate,
      opposing_batting_average_against_pitcher,
      opposing_strikeout_rate_against_pitcher,
    } = pitcher;

    const vegasLine = generateVegasLine(pitcher);

    return (
      <div>
        <p>
          <strong>Our machine learning model predicts:</strong>
        </p>
        <ul>
          <li>Innings Pitched: {predicted_innings_pitched}</li>
          <li>Strikeouts: {predicted_strikeouts_thrown}</li>
          <li>Earned Runs: {predicted_earned_runs}</li>
          <li>Hits Allowed: {predicted_hits_allowed}</li>
          <li>Walks Issued: {predicted_walks_issued}</li>
        </ul>
        <p>
          <strong>Opposing Team Season Averages:</strong>
        </p>
        <ul>
          <li>
            Runs/Game: ~{opposing_team_average_runs_per_game_over_5_innings}{' '}
            over 5 innings, ~
            {opposing_team_average_runs_per_game_over_6_innings} over 6 innings
          </li>
          <li>Season Batting Avg: {opposing_team_season_batting_average}</li>
          <li>
            Season Strikeout Rate: {opposing_team_strikeout_rate} (
            {(((opposing_team_strikeout_rate * 27) / 9) * 5).toFixed(2)} K over
            5 IP, {(((opposing_team_strikeout_rate * 27) / 9) * 6).toFixed(2)} K
            over 6 IP)
          </li>
        </ul>
        {opposing_batting_average_against_pitcher &&
          opposing_strikeout_rate_against_pitcher && (
            <>
              <p>
                <strong>Pitcher vs. Opposing Team:</strong>
              </p>
              <ul>
                <li>
                  Opposing Batting Avg:{' '}
                  {opposing_batting_average_against_pitcher} (
                  {calculatePredictedHits(
                    opposing_batting_average_against_pitcher,
                    predicted_innings_pitched,
                  )}{' '}
                  predicted hits based on average)
                </li>
                <li>
                  Opposing Strikeout Rate:{' '}
                  {opposing_strikeout_rate_against_pitcher} (
                  {calculatePredictedStrikeouts(
                    opposing_strikeout_rate_against_pitcher,
                    predicted_innings_pitched,
                  )}{' '}
                  predicted strikeouts based on strikeout rate)
                </li>
              </ul>
            </>
          )}
        {vegasLine && (
          <>
            <p>
              <strong>Vegas Line:</strong>
            </p>
            <ul>
              {vegasLine.split('\n').map((line, index) => (
                <li key={index}>{line}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (isLoading) {
    return <LoadingSpinner text="Loading pitcher cheat sheet..." />;
  }

  const teams = [...new Set(pitcherData.map((pitcher) => pitcher.player_team))];

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6 border-b pb-4">
        <h1 className="text-2xl font-bold text-gray-900">
          Pitcher Cheat Sheet for {date}
        </h1>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-800 transition"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>

      <div className="space-y-6">
        {teams.map((team) => (
          <div
            key={team}
            className="border border-gray-300 rounded-lg p-4 shadow-sm"
          >
            <div
              className="flex justify-between items-center cursor-pointer text-lg font-semibold text-gray-700 hover:text-blue-600 transition"
              onClick={() => toggleTeamExpansion(team)}
            >
              {team}
              <img
                src={
                  expandedTeams[team]
                    ? '/assets/icons/misc/collapse_icon.png'
                    : '/assets/icons/misc/expand_icon.png'
                }
                alt={expandedTeams[team] ? 'Collapse' : 'Expand'}
                className="w-6 h-6"
              />
            </div>
            {expandedTeams[team] && (
              <div className="mt-4 space-y-4">
                {pitcherData
                  .filter((pitcher) => pitcher.player_team === team)
                  .map(({ player_name, player_team, ...rest }) => (
                    <div
                      key={player_name}
                      className="bg-gray-50 p-4 rounded-lg shadow-md"
                    >
                      <div className="font-semibold text-gray-800">
                        {player_name} -{' '}
                        <span className="text-blue-500">{player_team}</span>
                      </div>
                      <div className="mt-3 text-sm text-gray-700">
                        {generateWriteup({ player_name, player_team, ...rest })}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PitcherCheatSheet;
