import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import apiClient from '../api/apiClient.js';
import LoadingSpinner from '../components/common/LoadingSpinner.js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Public routes that do not require authentication
  const publicRoutes = [
    '/',
    '/login',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/privacy-policy',
    '/terms-conditions',
    '/confirm-email',
  ];

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');

      if (token) {
        try {
          const response = await apiClient.get(
            `${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}check-auth/`,
          );

          const { must_change_password, user, subscriptionStatus } = response;

          setIsAuthenticated(true);
          setUser(user || null);
          setMustChangePassword(must_change_password);

          if (
            must_change_password &&
            location.pathname !== '/change-password'
          ) {
            navigate('/change-password');
            return;
          }

          if (subscriptionStatus === 'expired') {
            navigate('/dashboard');
          }
        } catch (error) {
          if (
            error.response?.status === 403 &&
            error.response?.data?.subscriptionStatus === 'expired'
          ) {
            // Allow expired users to access the dashboard
            setIsAuthenticated(true);
            setUser(null);
            navigate('/dashboard');
          } else {
            setIsAuthenticated(false);
            setUser(null);
            localStorage.removeItem('accessToken');
            navigate('/login');
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
        if (!publicRoutes.includes(location.pathname)) {
          navigate('/login');
        }
        setIsLoading(false);
      }
    };

    checkAuthentication();
    // eslint-disable-next-line
  }, [navigate, location.pathname]);

  // Logout function to clear session and redirect
  const logout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    setUser(null);
    navigate('/login');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, mustChangePassword, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the AuthContext
export const useAuth = () => React.useContext(AuthContext);
