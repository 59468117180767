const faqData = {
  'What is SportBetData.ai?':
    'SportBetData.ai is a platform providing daily sport insights driven by technology. We believe sports betting is not about being lucky or being a professional gambler, but simply having the right data in your hands!',
  'Are you on social media?':
    '@sportbetdata on Twitter & Instagram. Please follow to stay up to date with latest news.',
  'What sports are currently hosted on your platform?': 'NBA, NFL, MLB, WNBA',
  'How much does your app cost?':
    '6.99/day for all sports (9.99 on Sunday from September to January), $16.99/month for all sports or $149.99/year for all sports. Occasional deals during the holidays.',
  'How do I cancel my subscription?':
    'Subscriptions are not auto renewed. If you do not wish to renew your subscription, we do not keep your data on file for renewal.',
  'What kind of insights does SportBetData.ai provide?':
    'We show trends, predictions, available vegas odds, and additional insights such as weather conditions for outdoor sports, important team metrics, and niche reports only found on our app.',
  'When is data first available for a sport?':
    'For accurate forecasting, NBA & WNBA require 7 days into the season, NFL requires 3 weeks into the season, MLB requires 15 days into the season.',
  'Is my personal information secure?':
    'Yes, we use industry-standard security measures to protect your personal information.',
  'How often is the data updated?':
    'Our external providers provide all necessary data by 8AM EST, though they may experience delays. The data is refreshed periodically throughout the day to ensure accuracy before game time.',
  'What sportbook do you use for the odds displayed on the app?':
    'The odds displayed on the app are from Draftkings.',
  'What if I lose my bet tailing your predictions?':
    'sportbetdata.ai are not cappers and no predictions should be tailed blindly. We simply help you make informed decisions!',
  'What payment methods are accepted?': 'We accept major credit cards.',
  'How do I contact customer support?':
    "You can contact customer support through the 'File Issue' section or reach out to us on Twitter.",
  'Can businesses partner with SportBetData.ai?':
    'Yes, we offer partnership programs for businesses and influencers. Please reach out!',
  'What is the refund policy?':
    'Refunds are handled on a case-by-case basis. Please contact customer support for assistance with any refund requests.',
  'Is there a free trial available?':
    'We do not offer free trials, but we occasionally post promo codes on our social media. Make sure to follow us to stay updated. We also provide a very affordable day pass.',
};

export default faqData;
