import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '../../common/Button.js';
import LoadingSpinner from '../../common/LoadingSpinner.js';

const UserSettings = () => {
  const [userInfo, setUserInfo] = useState({ first_name: '', last_name: '' });
  const [passwordInfo, setPasswordInfo] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });
  const [originalUserInfo, setOriginalUserInfo] = useState({});
  const [message, setMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_USER_API_TYPE}user-info/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setUserInfo(response.data);
        setOriginalUserInfo(response.data);
      } catch (error) {
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo({ ...passwordInfo, [name]: value });
  };

  const handleUserInfoSubmit = (e) => {
    e.preventDefault();
    setConfirmType('userInfo');
    setShowConfirmModal(true);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    setConfirmType('password');
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    const token = localStorage.getItem('accessToken');
    setShowConfirmModal(false);

    if (confirmType === 'userInfo') {
      const updatedInfo = Object.keys(userInfo).reduce((acc, key) => {
        if (userInfo[key] !== originalUserInfo[key]) acc[key] = userInfo[key];
        return acc;
      }, {});

      try {
        if (Object.keys(updatedInfo).length > 0) {
          await axios.put(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_USER_API_TYPE}update-user/`,
            updatedInfo,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setMessage('User info updated successfully.');
          setOriginalUserInfo(userInfo);
        }
      } catch (error) {
        setMessage('Failed to update user info.');
      }
    } else if (confirmType === 'password') {
      try {
        if (passwordInfo.new_password === passwordInfo.confirm_password) {
          await axios.put(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_USER_API_TYPE}update-password/`,
            passwordInfo,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setPasswordMessage('Password updated successfully.');
          setPasswordInfo({
            current_password: '',
            new_password: '',
            confirm_password: '',
          });
        } else {
          setPasswordMessage('New password and confirm password do not match.');
        }
      } catch (error) {
        setPasswordMessage(
          'Failed to update password. Incorrect current password.',
        );
      }
    }
  };

  if (loading) {
    return <LoadingSpinner text="Loading user settings..." />;
  }

  return (
    <div className="relative min-h-screen">
      {/* Back Button */}
      <div className="absolute top-4 left-4 sm:top-8 sm:left-8">
        <Button
          onClick={() => navigate(-1)}
          variant="pureBlack"
          size="slimSmall"
          className="border border-gray-300 shadow-lg hover:shadow-xl"
        >
          Back
        </Button>
      </div>

      {/* Content */}
      <div className="max-w-6xl mx-auto px-6 py-12">
        <h1 className="text-4xl font-heading text-neutral mb-12 text-center">
          User Settings
        </h1>

        {/* Section Container */}
        <div className="flex flex-col md:flex-row gap-12">
          {/* Update User Info Section */}
          <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-heading text-neutral mb-6">
                Update User Info
              </h2>
              <form onSubmit={handleUserInfoSubmit} className="space-y-4">
                <div>
                  <label htmlFor="first_name" className="block text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={userInfo.first_name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </div>
                <div>
                  <label htmlFor="last_name" className="block text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={userInfo.last_name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </div>
              </form>
            </div>
            <button
              type="submit"
              className="w-full mt-4 bg-primary text-white py-2 rounded-lg hover:bg-primary-dark"
            >
              Update Info
            </button>
            {message && (
              <p className="mt-2 text-sm text-primary text-center">{message}</p>
            )}
          </div>

          {/* Separator */}
          <div className="hidden md:block w-px bg-gray-300"></div>

          {/* Change Password Section */}
          <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-heading text-neutral mb-6">
                Change Password
              </h2>
              <form onSubmit={handlePasswordSubmit} className="space-y-4">
                <div>
                  <label
                    htmlFor="current_password"
                    className="block text-gray-700"
                  >
                    Current Password
                  </label>
                  <input
                    type="password"
                    id="current_password"
                    name="current_password"
                    value={passwordInfo.current_password}
                    onChange={handlePasswordChange}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </div>
                <div>
                  <label htmlFor="new_password" className="block text-gray-700">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="new_password"
                    name="new_password"
                    value={passwordInfo.new_password}
                    onChange={handlePasswordChange}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </div>
                <div>
                  <label
                    htmlFor="confirm_password"
                    className="block text-gray-700"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    value={passwordInfo.confirm_password}
                    onChange={handlePasswordChange}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </div>
              </form>
            </div>
            <button
              type="submit"
              className="w-full mt-4 bg-primary text-white py-2 rounded-lg hover:bg-primary-dark"
            >
              Change Password
            </button>
            {passwordMessage && (
              <p className="mt-2 text-sm text-primary text-center">
                {passwordMessage}
              </p>
            )}
          </div>
        </div>

        {/* Confirmation Modal */}
        {showConfirmModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-heading mb-4">Confirm Changes</h3>
              <p className="text-gray-700 mb-4">
                Are you sure you want to update your information?
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleConfirm}
                  className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark"
                >
                  Yes
                </button>
                <button
                  onClick={() => setShowConfirmModal(false)}
                  className="text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSettings;
