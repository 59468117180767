/**
 * Extracts a user-friendly error message from an Axios error response.
 * @param {Object} error - The Axios error object.
 * @returns {string} - A human-readable error message.
 */
const extractErrorMessage = (error) => {
  console.error('Error object:', error);

  if (error.response) {
    const { status, data } = error.response;

    if (data && typeof data === 'object' && data.error) {
      return data.error;
    }

    let errorMessage = '';
    if (typeof data === 'string') {
      errorMessage = data;
    } else if (Array.isArray(data)) {
      errorMessage = data.join(', ');
    } else if (typeof data === 'object') {
      errorMessage = Object.values(data).flat().join(', ');
    }

    if (status === 400)
      return `Bad Request: ${errorMessage || 'Invalid request.'}`;
    if (status === 401)
      return `Unauthorized: ${errorMessage || 'Please log in.'}`;
    if (status === 403) return `Forbidden: ${errorMessage || 'Access denied.'}`;
    if (status === 404)
      return `Not Found: ${errorMessage || 'Resource not found.'}`;
    if (status >= 500)
      return `Server Error: ${errorMessage || 'An error occurred on the server.'}`;

    return errorMessage || 'An unexpected error occurred.';
  }

  // Handle network errors or unexpected cases
  if (error.message && error.message.includes('Network Error')) {
    return 'Network error. Please check your connection and try again.';
  }

  return `Unexpected error: ${error.message || 'Unknown error occurred.'}`;
};

/**
 * Handles errors from API calls and throws a user-friendly message.
 * @param {Object} error - Error object from Axios response.
 * @throws {Error} - Customized error message.
 */
export const handleError = (error) => {
  const errorMessage = extractErrorMessage(error);
  throw new Error(errorMessage);
};
