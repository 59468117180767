import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CheatSheetDropdown = ({ sport_id, date }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  // Cheat sheet options by sport
  const cheatSheetOptions = {
    1: [
      { value: 'pitcher-cheat-sheet', label: 'Pitcher Cheat Sheet' },
      { value: 'hr-drought-cheat-sheet', label: 'HR Droughts' },
      { value: 'hit-drought-cheat-sheet', label: 'Hit Droughts' },
      {
        value: 'hot-batter-cheat-sheet',
        label: 'Hot Batters with Good History',
      },
      { value: 'good-history-cheat-sheet', label: 'Batters with Good History' },
      {
        value: 'hr-drought-good-history-batter-cheat-sheet',
        label: 'HR Drought Batters with Good History',
      },
      {
        value: 'hit-drought-good-history-batter-cheat-sheet',
        label: 'Hit Drought Batters with Good History',
      },
      { value: 'mlb-trend-history-cheat-sheet', label: 'Pitcher Trend Report' },
    ],
    2: [
      { value: 'player-cheat-sheet', label: 'Player Cheat Sheet' },
      {
        value: 'nfl-trend-history-cheat-sheet',
        label: 'Trend History Cheat Sheet',
      },
      {
        value: 'nfl-defense-position-td-cheat-sheet',
        label: 'Defense Allowed TD Metrics',
      },
      { value: 'nfl-cluster-report', label: 'Cluster Report' },
      {
        value: 'nfl-reverse-correlations',
        label: 'Potential Inverse Correlations Report',
      },
    ],
    3: [
      {
        value: 'nba-trend-history-cheat-sheet',
        label: 'Trend History Cheat Sheet',
      },
      {
        value: 'nba-position-metrics-report',
        label: 'Position Metrics Report',
      },
      { value: 'nba-cluster-report', label: 'PPG Cluster Report' },
      {
        value: 'nba-player-opposing-defense-report',
        label: 'Player Opposing Defense Report',
      },
      {
        value: 'nba-reverse-correlations',
        label: 'Potential Inverse Correlations Report',
      },
    ],
    4: [
      {
        value: 'wnba-trend-history-cheat-sheet',
        label: 'Trend History Cheat Sheet',
      },
    ],
  };

  // Determine cheat sheets for the selected sport
  const options = cheatSheetOptions[sport_id] || [];

  const handleNavigate = () => {
    if (selectedOption) {
      const url =
        sport_id === '2' || sport_id === '3' || sport_id === '4'
          ? `/${selectedOption}/${sport_id}/`
          : `/${selectedOption}/${sport_id}/${date}`;
      navigate(url);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row items-center gap-4">
      {/* Dropdown */}
      <select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        className={`w-full sm:w-auto px-4 py-2 border rounded-lg bg-white text-neutral-700 border-gray-300 
          focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary disabled:bg-gray-100 disabled:cursor-not-allowed`}
        disabled={options.length === 0}
        aria-label="Select Cheat Sheet"
      >
        <option value="" disabled>
          {options.length === 0
            ? 'No Cheat Sheets Available'
            : 'Select Cheat Sheet'}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {/* Button */}
      <button
        onClick={handleNavigate}
        className={`px-6 py-2 bg-primary text-white rounded-lg shadow hover:bg-primary-600 
          focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition 
          disabled:bg-gray-300 disabled:cursor-not-allowed`}
        disabled={!selectedOption || options.length === 0}
      >
        Go
      </button>
    </div>
  );
};

export default CheatSheetDropdown;
