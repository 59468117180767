import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { confirmEmail, resendEmailCode } from '../../../api/authApi.js';

const ConfirmEmail = () => {
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Get the email passed from the Register component
  const email = location.state?.email || '';

  const handleConfirmEmail = async (e) => {
    e.preventDefault();
    try {
      await confirmEmail(token);
      setSuccessMessage('Email confirmed successfully.');
      navigate('/dashboard');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResendCode = async () => {
    try {
      await resendEmailCode();
      setSuccessMessage('A new code has been sent to your email.');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-white">
      {/* Main Content */}
      <div className="flex flex-col items-center justify-center flex-grow px-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 animate-slideUp">
          <h2 className="text-2xl font-heading text-neutral mb-4">
            Confirm Email
          </h2>
          <p className="text-sm text-gray-600 mb-6">
            A confirmation code has been sent to{' '}
            <span className="text-primary">{email}</span>. Please enter the code
            below. If you don't receive it within 30 seconds, click "Resend
            Code."
          </p>
          <form onSubmit={handleConfirmEmail}>
            <div className="mb-4">
              <label
                htmlFor="token"
                className="block text-sm font-medium text-primary mb-1"
              >
                Confirmation Code
              </label>
              <input
                id="token"
                type="text"
                name="token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg font-medium hover:bg-green transition-all duration-300 mb-4"
            >
              Confirm
            </button>
          </form>
          {errorMessage && (
            <p className="text-red text-sm mb-4">{errorMessage}</p>
          )}
          {successMessage && (
            <p className="text-green text-sm mb-4">{successMessage}</p>
          )}
          <button
            onClick={handleResendCode}
            className="w-full bg-secondary text-white py-3 rounded-lg font-medium transition-all duration-300 mb-4"
          >
            Resend Code
          </button>
          <div className="text-center">
            <Link to="/" className="text-blue hover:underline text-sm">
              Back to Home
            </Link>
          </div>
          <p className="text-xs text-gray mt-6">
            Verifying your email is not necessary to proceed with the
            application. You can click "Back to Home." However, if you forget
            your credentials with an active subscription, you will need to reach
            out to <span className="text-primary">support@sportbetdata.ai</span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
