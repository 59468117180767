import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  loginUser,
  registerUser,
  confirmEmail,
  resendEmailCode,
  forgotPassword,
  refreshToken,
  isAuthenticated,
} from '../../api/authApi';
import { handleError } from '../../utils/errorHandler';

/**
 * Thunk to handle user login
 */
export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await loginUser(email, password);
      return response;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

/**
 * Thunk to handle user registration
 */
export const userRegister = createAsyncThunk(
  'auth/register',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await registerUser(formData);
      return response;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

/**
 * Thunk to handle email confirmation
 */
export const emailConfirmation = createAsyncThunk(
  'auth/confirmEmail',
  async (token, { rejectWithValue }) => {
    try {
      return await confirmEmail(token);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

/**
 * Thunk to resend email confirmation code
 */
export const resendConfirmationEmail = createAsyncThunk(
  'auth/resendEmail',
  async (_, { rejectWithValue }) => {
    try {
      return await resendEmailCode();
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

/**
 * Thunk to handle forgot password request
 */
export const passwordResetRequest = createAsyncThunk(
  'auth/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      return await forgotPassword(email);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

/**
 * Thunk to refresh user token
 */
export const refreshUserToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      return await refreshToken();
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

const initialState = {
  user: null,
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  isAuthenticated: isAuthenticated(),
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    clearAuthError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle user login
      .addCase(userLogin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
        state.isAuthenticated = true;
        localStorage.setItem('accessToken', action.payload.access);
        localStorage.setItem('refreshToken', action.payload.refresh);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle user registration
      .addCase(userRegister.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle email confirmation
      .addCase(emailConfirmation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(emailConfirmation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(emailConfirmation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle password reset
      .addCase(passwordResetRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(passwordResetRequest.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(passwordResetRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle token refresh
      .addCase(refreshUserToken.fulfilled, (state, action) => {
        state.accessToken = action.payload;
        state.isAuthenticated = true;
        localStorage.setItem('accessToken', action.payload);
      })
      .addCase(refreshUserToken.rejected, (state) => {
        state.isAuthenticated = false;
      });
  },
});

export const { logout, clearAuthError } = authSlice.actions;
export default authSlice.reducer;
