// General Components
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthCheck.js';
import StripeContext from './context/StripeContext';
import ResponsiveWrapper from './components/layout/ResponsiveWrapper.js';

// Auth Components
import Home from './components/pages/auth/Home';
import Login from './components/pages/auth/Login';
import Register from './components/pages/auth/Register';
import ForgotPassword from './components/pages/auth/ForgotPassword';
import ResetPassword from './components/pages/auth/ResetPassword';
import ConfirmEmail from './components/pages/auth/ConfirmEmail';

// Misc Components
import PrivacyPolicy from './components/pages/misc/PrivacyPolicy';
import TermsConditions from './components/pages/misc/TermsConditions';
import NotFound from './components/pages/misc/NotFound.js';

// Dashboard Components
import Dashboard from './components/pages/dashboard/Dashboard';
import Faq from './components/pages/dashboard/Faq';
import FileIssue from './components/pages/dashboard/FileIssue';
import UserSettings from './components/pages/dashboard/UserSettings';

// Data Components
import Sport from './components/pages/data/core/Sport';
import GameDetailsPage from './components/user/GameDetailsPage';

// Baseball Cheat Sheets
import GoodHistoryCheatSheet from './components/data/baseball/GoodHistoryCheatSheet';
import HRDroughtBatterGoodHistoryCheatSheet from './components/data/baseball/HRDroughtBatterGoodHistoryCheatSheet';
import HitDroughtBatterGoodHistoryCheatSheet from './components/data/baseball/HitDroughtBatterGoodHistoryCheatSheet';
import HotBatterCheatSheet from './components/data/baseball/HotBatterCheatSheet';
import HRDroughtCheatSheet from './components/data/baseball/HRDroughtCheatSheet';
import HitDroughtCheatSheet from './components/data/baseball/HitDroughtCheatSheet';
import HRDuos from './components/data/baseball/HRDuos';
import HRStats from './components/data/baseball/HRStats';
import PitcherCheatSheet from './components/data/baseball/PitcherCheatSheet';
import MLBPitcherPropTrendHistoryCheatSheet from './components/data/baseball/MLBPitcherPropTrendHistoryCheatSheet';

// NFL Cheat Sheets
import PlayerCheatSheet from './components/data/football/PlayerCheatSheet';
import NFLTrendHistoryCheatSheet from './components/data/football/NFLTrendHistoryCheatSheet';
import DefenseVsPositionTDCheatSheet from './components/data/football/DefenseVsPositionTDCheatSheet';
import NFLClusterReport from './components/data/football/NFLClusterReport';
import NFLReverseCorrelations from './components/data/football/NFLReverseCorrelations';

// WNBA Cheat Sheets
import WNBATrendHistoryCheatSheet from './components/data/basketball/WNBATrendHistoryCheatSheet';

// NBA Cheat Sheets
import NBATrendHistoryCheatSheet from './components/data/basketball/NBATrendHistoryCheatSheet';
import NBAPositionMetricsReport from './components/data/basketball/NBAPositionMetrics';
import NBAClusterReport from './components/data/basketball/NBAClusterReport';
import NBAPlayerDAvgReport from './components/data/basketball/NBAPlayerDAvg';
import NBAReverseCorrelations from './components/data/basketball/NBAReverseCorrelations';

/* 
########################################################################
# Core Logic
########################################################################
*/

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <ResponsiveWrapper>
      <StripeContext>
        <div className="App">
          <Routes>
            {/* Public Routes */}
            <Route
              path="/"
              element={
                !isAuthenticated ? <Home /> : <Navigate to="/dashboard" />
              }
            />
            <Route
              path="/login"
              element={
                !isAuthenticated ? <Login /> : <Navigate to="/dashboard" />
              }
            />
            <Route
              path="/register"
              element={
                !isAuthenticated ? <Register /> : <Navigate to="/dashboard" />
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/reset-password/:uid/:token"
              element={<ResetPassword />}
            />
            <Route path="/confirm-email" element={<ConfirmEmail />} />

            {/* Protected Routes - Dashboard */}
            <Route
              path="/dashboard"
              element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="/faq"
              element={isAuthenticated ? <Faq /> : <Navigate to="/" />}
            />
            <Route
              path="/file-issue"
              element={isAuthenticated ? <FileIssue /> : <Navigate to="/" />}
            />
            <Route
              path="/user-settings"
              element={isAuthenticated ? <UserSettings /> : <Navigate to="/" />}
            />

            {/* Protected Routes - Data */}
            <Route
              path="/sport/:sport_id"
              element={isAuthenticated ? <Sport /> : <Navigate to="/" />}
            />
            <Route
              path="/game-details/:sportName/:date/:game_id"
              element={
                isAuthenticated ? <GameDetailsPage /> : <Navigate to="/" />
              }
            />

            {/* Baseball Cheat Sheets */}
            <Route
              path="/hot-batter-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? <HotBatterCheatSheet /> : <Navigate to="/" />
              }
            />
            <Route
              path="/good-history-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? (
                  <GoodHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/hr-drought-good-history-batter-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? (
                  <HRDroughtBatterGoodHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/hit-drought-good-history-batter-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? (
                  <HitDroughtBatterGoodHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/pitcher-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? <PitcherCheatSheet /> : <Navigate to="/" />
              }
            />
            <Route
              path="/hr-drought-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? <HRDroughtCheatSheet /> : <Navigate to="/" />
              }
            />
            <Route
              path="/hit-drought-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? <HitDroughtCheatSheet /> : <Navigate to="/" />
              }
            />
            <Route
              path="/mlb-trend-history-cheat-sheet/:sport_id/:date"
              element={
                isAuthenticated ? (
                  <MLBPitcherPropTrendHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            {/* Publicly Accessible Baseball Routes */}
            <Route path="/hr-stats" element={<HRStats />} />
            <Route path="/hr-duos" element={<HRDuos />} />

            {/* NFL Cheat Sheets */}
            <Route
              path="/player-cheat-sheet/:sport_id"
              element={
                isAuthenticated ? <PlayerCheatSheet /> : <Navigate to="/" />
              }
            />
            <Route
              path="/nfl-trend-history-cheat-sheet/:sport_id"
              element={
                isAuthenticated ? (
                  <NFLTrendHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/nfl-defense-position-td-cheat-sheet/:sport_id"
              element={
                isAuthenticated ? (
                  <DefenseVsPositionTDCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/nfl-cluster-report/:sport_id"
              element={
                isAuthenticated ? <NFLClusterReport /> : <Navigate to="/" />
              }
            />
            <Route
              path="/nfl-reverse-correlations/:sport_id"
              element={
                isAuthenticated ? (
                  <NFLReverseCorrelations />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            {/* WNBA Basketball Cheat Sheets */}
            <Route
              path="/wnba-trend-history-cheat-sheet/:sport_id"
              element={
                isAuthenticated ? (
                  <WNBATrendHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            {/* NBA Basketball Cheat Sheets */}
            <Route
              path="/nba-trend-history-cheat-sheet/:sport_id"
              element={
                isAuthenticated ? (
                  <NBATrendHistoryCheatSheet />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/nba-position-metrics-report/:sport_id"
              element={
                isAuthenticated ? (
                  <NBAPositionMetricsReport />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/nba-cluster-report/:sport_id"
              element={
                isAuthenticated ? <NBAClusterReport /> : <Navigate to="/" />
              }
            />
            <Route
              path="/nba-player-opposing-defense-report/:sport_id"
              element={
                isAuthenticated ? <NBAPlayerDAvgReport /> : <Navigate to="/" />
              }
            />
            <Route
              path="/nba-reverse-correlations/:sport_id"
              element={
                isAuthenticated ? (
                  <NBAReverseCorrelations />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            {/* Fallback for Malformed or Invalid Routes */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/error" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </StripeContext>
    </ResponsiveWrapper>
  );
}

export default App;

/* 
########################################################################
# END
########################################################################
*/
