import apiClient, { buildApiUrl } from './apiClient';
import { handleError } from '../utils/errorHandler';

/**
 * Fetch MLB batter cheat sheet data for a specific date.
 * @param {string} date - The date for which to retrieve batter data.
 * @returns {Promise<Array>} - A list of batter predictions.
 * @throws {Error} - If the API call fails.
 */
export const getBatterCheatSheetData = async (date) => {
  try {
    const { batter_predictions } = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    if (!batter_predictions) return [];

    const uniqueBatterData = Object.values(
      batter_predictions.reduce((acc, batter) => {
        if (
          !acc[batter.player_name] ||
          acc[batter.player_name].predicted_hits < batter.predicted_hits
        ) {
          acc[batter.player_name] = batter;
        }
        return acc;
      }, {}),
    );

    uniqueBatterData.sort(
      (a, b) =>
        a.player_team.localeCompare(b.player_team) ||
        b.predicted_hits - a.predicted_hits,
    );

    return uniqueBatterData;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB batters with good history against pitchers for a specific date.
 * @param {string} date - The date for which to retrieve data.
 * @returns {Promise<Array>} - A list of batters with good pitcher history.
 * @throws {Error} - If the API call fails.
 */
export const getGoodHistoryBatterData = async (date) => {
  try {
    const { batters_good_pitcher_history } = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    return batters_good_pitcher_history || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB hit drought batter data with good history for a specific date.
 * @param {string} date - The date for which to retrieve the data.
 * @returns {Promise<Object>} - An object containing drought data and game summaries.
 * @throws {Error} - If the API call fails.
 */
export const getHitDroughtBatterGoodHistoryData = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    return {
      droughtData: response.hit_droughts_good_pitcher_history || [],
      gameSummaries: response.game_summary || [],
    };
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB hit drought cheat sheet data for a specific date.
 * @param {string} date - The date for which to retrieve the data.
 * @returns {Promise<Object>} - An object containing hit drought data and game summaries.
 * @throws {Error} - If the API call fails.
 */
export const getHitDroughtData = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    return {
      hitDroughtData: response.hit_droughts || [],
      gameSummaries: response.game_summary || [],
    };
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB hot batters cheat sheet data for a specific date.
 * @param {string} date - The date for which to retrieve data.
 * @returns {Promise<Array>} - A list of hot batters with good history.
 * @throws {Error} - If the API call fails.
 */
export const getHotBatterData = async (date) => {
  try {
    const { hot_batters_good_pitcher_history } = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    return hot_batters_good_pitcher_history || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB HR drought batters cheat sheet data for a specific date.
 * @param {string} date - The date for which to retrieve data.
 * @returns {Promise<Object>} - A list of HR drought batters and game summaries.
 * @throws {Error} - If the API call fails.
 */
export const getHRDroughtBatterData = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    return {
      droughts: response.droughts_good_pitcher_history || [],
      gameSummaries: response.game_summary || [],
    };
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB HR drought data for a specific date.
 * @param {string} date - The date for which to retrieve HR drought data.
 * @returns {Promise<Object>} - A list of HR droughts and game summaries.
 * @throws {Error} - If the API call fails.
 */
export const getHRDroughtData = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    return {
      homeRunDroughts: response.home_run_droughts || [],
      gameSummaries: response.game_summary || [],
    };
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB HR duos based on the selected interval.
 * @param {string} interval - The time period for the HR duos (e.g. '7', '14', '30', 'season').
 * @returns {Promise<Array>} - A list of HR duos with player combinations and HR dates.
 * @throws {Error} - If the API call fails.
 */
export const getHRDuos = async (interval) => {
  try {
    const response = await apiClient.post(
      buildApiUrl('data', `mlb/retrieve-mlb-hr-duos/`),
      { interval },
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetches a list of MLB players for selection.
 * @returns {Promise<Array>} - A sorted list of MLB players.
 * @throws {Error} - If the API call fails.
 */
export const getMLBPlayers = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/retrieve-mlb-players/`),
    );

    // Sort players alphabetically by name
    return response.sort((a, b) => a.player_name.localeCompare(b.player_name));
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB HR stats for selected players.
 * @param {Array} playerIds - An array of player IDs.
 * @returns {Promise<Object>} - HR stats data.
 * @throws {Error} - If the API call fails.
 */
export const getMLBHRStats = async (playerIds) => {
  try {
    const response = await apiClient.post(
      buildApiUrl('data', `mlb/retrieve-mlb-hr-stats/`),
      { player_ids: playerIds },
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB pitcher prop trend history data.
 * @returns {Promise<Array>} - A sorted list of pitcher prop trends.
 * @throws {Error} - If the API call fails.
 */
export const getMLBPitcherPropTrends = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/retrieve-mlb-pitcher-prop-trends/`),
    );

    // Sort the data by player name and metric
    return response.sort((a, b) => {
      if (a.player_name < b.player_name) return -1;
      if (a.player_name > b.player_name) return 1;
      return a.metric.localeCompare(b.metric);
    });
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch MLB pitcher cheat sheet data for a specific date.
 * @param {string} date - The date for which to retrieve pitcher data.
 * @returns {Promise<Array>} - A list of pitcher predictions.
 * @throws {Error} - If the API call fails.
 */
export const getPitcherCheatSheetData = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `mlb/pdf-data/${date}/0/`),
    );

    const pitcherPredictions = response.pitcher_predictions || [];

    // Remove duplicates by player name and keep the entry with the max predicted innings pitched
    const uniquePitcherData = Object.values(
      pitcherPredictions.reduce((acc, pitcher) => {
        if (
          !acc[pitcher.player_name] ||
          acc[pitcher.player_name].predicted_innings_pitched <
            pitcher.predicted_innings_pitched
        ) {
          acc[pitcher.player_name] = pitcher;
        }
        return acc;
      }, {}),
    );

    // Sort by team name and predicted innings pitched in descending order
    uniquePitcherData.sort(
      (a, b) =>
        a.player_team.localeCompare(b.player_team) ||
        b.predicted_innings_pitched - a.predicted_innings_pitched,
    );

    return uniquePitcherData;
  } catch (error) {
    handleError(error);
  }
};
