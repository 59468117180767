import React, { useState } from 'react';

const FAQ = ({ faqData }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      {Object.entries(faqData).map(([question, answer], index) => (
        <div
          key={index}
          className="border border-neutral/20 rounded-lg shadow-md overflow-hidden transition-all bg-white"
        >
          {/* Question Section */}
          <div
            onClick={() => toggleFAQ(index)}
            className={`cursor-pointer bg-neutral text-white flex items-center justify-between px-6 py-4 ${
              activeIndex === index ? 'rounded-t-lg' : 'rounded-lg'
            }`}
          >
            <h3 className="font-heading text-sm md:text-2xl tracking-wide text-center w-full">
              {question}
            </h3>
            <span className="text-2xl transition-transform duration-300">
              {activeIndex === index ? '-' : '+'}
            </span>
          </div>

          {/* Answer Section */}
          <div
            className={`transition-max-height duration overflow-hidden px-6 ${
              activeIndex === index ? 'max-h-screen py-4' : 'max-h-0'
            }`}
          >
            <p className="font-body text-sm md:text-base text-black leading-relaxed">
              {answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
