import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import '../../styles/data/GameDetails.css';
import LoadingSpinner from '../common/LoadingSpinner';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const domeTeams = new Set([
  'Toronto',
  'Milwaukee',
  'Miami',
  'Houston',
  'Texas',
  'Arizona',
]);

const MLBGameDetails = () => {
  const { date, game_id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [teamMetricsData, setTeamMetricsData] = useState([]);
  const [error, setError] = useState('');
  const [expandedSections, setExpandedSections] = useState({});
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/1/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (permissionResponse.status === 200) {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/pdf-data/${date}/${game_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setGameData(response.data);

          // Fetch the team metrics data
          const metricsResponse = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/team-metrics/${date}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setTeamMetricsData(metricsResponse.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkPermissionsAndFetchData();
  }, [date, game_id, navigate, handleError]);

  const toggleExpansion = (teamName, period) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [`${teamName}-${period}`]: !prevExpandedSections[`${teamName}-${period}`],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!gameData) {
    return <LoadingSpinner text="Loading game data..." />;
  }

  const calculatePredictedStrikeouts = (strikeoutRate, inningsPitched) => {
    const predictedStrikeoutsPer9Innings = strikeoutRate * 27;
    return (predictedStrikeoutsPer9Innings / 9) * inningsPitched;
  };

  const calculatePredictedHits = (battingAverage, inningsPitched) => {
    const predictedHitsPer9Innings = battingAverage * 27;
    return (predictedHitsPer9Innings / 9) * inningsPitched;
  };

  const {
    games,
    game_summary,
    batter_predictions,
    pitcher_predictions,
    recent_batter_performances,
    recent_pitcher_performances,
    weather_data,
    odd_comparison,
    player_odds,
  } = gameData;

  const selectedGame = games.find(
    (game) => game.game_id.toString() === game_id,
  );
  if (!selectedGame) {
    return <div>No game data available for this game ID.</div>;
  }

  const awayTeam = { name: selectedGame.away_team };
  const homeTeam = { name: selectedGame.home_team };

  const gameSummary = game_summary.find(
    (summary) => summary.game_id.toString() === game_id,
  );
  const awayTeamPoints = gameSummary
    ? parseFloat(
        gameSummary.winner === awayTeam.name
          ? gameSummary.winner_total
          : gameSummary.loser_total,
      )
    : 0;
  const homeTeamPoints = gameSummary
    ? parseFloat(
        gameSummary.winner === homeTeam.name
          ? gameSummary.winner_total
          : gameSummary.loser_total,
      )
    : 0;
  const totalPoints = gameSummary ? parseFloat(gameSummary.total_runs) : 0;

  const weatherInfo =
    weather_data && weather_data.length > 0 ? weather_data[0] : null;

  const mapPitcherData = (players, performances, teamName, gameId) => {
    return players
      .filter(
        (player) =>
          player.game_id.toString() === gameId &&
          player.player_team === teamName,
      )
      .map((player) => ({
        name: player.player_name,
        opposing_batting_average_against_pitcher:
          player.opposing_batting_average_against_pitcher,
        opposing_strikeout_rate_against_pitcher:
          player.opposing_strikeout_rate_against_pitcher,
        opposing_team_season_batting_average:
          player.opposing_team_season_batting_average,
        opposing_team_strikeout_rate: player.opposing_team_strikeout_rate,
        opposing_team_average_runs_per_game_over_5_innings:
          player.opposing_team_average_runs_per_game_over_5_innings,
        opposing_team_average_runs_per_game_over_6_innings:
          player.opposing_team_average_runs_per_game_over_6_innings,
        opposing_team_average_runs_per_game_over_9_innings:
          player.opposing_team_average_runs_per_game_over_9_innings,
        predictions: [
          {
            metric: 'Innings Pitched',
            value: player.predicted_innings_pitched,
          },
          { metric: 'Strikeouts', value: player.predicted_strikeouts_thrown },
          { metric: 'Earned Runs', value: player.predicted_earned_runs },
          { metric: 'Hits Allowed', value: player.predicted_hits_allowed },
          { metric: 'Walks Issued', value: player.predicted_walks_issued },
        ],
        predictedStrikeouts: calculatePredictedStrikeouts(
          player.opposing_strikeout_rate_against_pitcher,
          player.predicted_innings_pitched,
        ),
        predictedHits: calculatePredictedHits(
          player.opposing_batting_average_against_pitcher,
          player.predicted_innings_pitched,
        ),
        recentPerformances: performances
          .filter(
            (performance) =>
              performance.player === player.player_name &&
              performance.player_team === teamName,
          )
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            game_display_name: performance.game_display_name,
            inningsPitched: performance.innings_pitched,
            strikeoutsThrown: performance.strikeouts_thrown,
            earnedRuns: performance.earned_runs,
            hitsAllowed: performance.hits_allowed,
            walksIssued: performance.walks_issued,
          })),
        opposingTeamStrikeoutsPer9Innings:
          player.opposing_team_strikeout_rate * 27,
        opposingTeamStrikeoutsPer6Innings:
          player.opposing_team_strikeout_rate * 18,
        opposingTeamStrikeoutsPer5Innings:
          player.opposing_team_strikeout_rate * 15,
      }));
  };

  const mapBatterData = (players, performances, teamName) => {
    return players
      .filter((player) => player.player_team === teamName)
      .map((player) => ({
        name: player.player_name,
        ab_against_opposing_pitcher: player.ab_against_opposing_pitcher,
        hits_against_opposing_pitcher: player.hits_against_opposing_pitcher,
        batting_average_against_opposing_pitcher:
          player.batting_average_against_opposing_pitcher,
        predictions: [
          { metric: 'Hits', value: player.predicted_hits },
          { metric: 'Home Runs', value: player.predicted_home_runs },
          { metric: 'Walks', value: player.predicted_walks },
        ],
        recentPerformances: performances
          .filter(
            (performance) =>
              performance.player === player.player_name &&
              performance.player_team === teamName,
          )
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            game_display_name: performance.game_display_name,
            at_bats: performance.at_bats,
            hits: performance.hits,
            home_runs: performance.home_runs,
            walks: performance.walks,
            strikeouts: performance.strikeouts,
          })),
      }));
  };

  const mapPlayerOdds = (playerName, playerOdds) => {
    return playerOdds
      .filter((odd) => odd.player_name === playerName)
      .map((odd) => ({
        metric: odd.metric,
        overUnder: odd.over_under,
        bettingOver: parseFloat(odd.betting_100_on_over_would_return),
        bettingUnder: parseFloat(odd.betting_100_on_under_would_return),
      }));
  };

  const awayPitchers = mapPitcherData(
    pitcher_predictions,
    recent_pitcher_performances,
    selectedGame.away_team,
    game_id,
  );
  const homePitchers = mapPitcherData(
    pitcher_predictions,
    recent_pitcher_performances,
    selectedGame.home_team,
    game_id,
  );
  const awayBatters = mapBatterData(
    batter_predictions,
    recent_batter_performances,
    selectedGame.away_team,
  );
  const homeBatters = mapBatterData(
    batter_predictions,
    recent_batter_performances,
    selectedGame.home_team,
  );

  const vegasMetricKeyMap = {
    Hits: 'hits',
    'Home Runs': 'home_runs',
    'Innings Pitched': 'inningsPitched',
    Strikeouts: 'strikeoutsThrown',
    'Earned runs': 'earnedRuns',
    'Hits allowed': 'hitsAllowed',
    Walks: 'walksIssued',
    'Total bases': 'total_bases',
    Rbis: 'rbis',
    'Runs scored': 'runs_scored',
    Outs: 'inningsPitched',
  };

  const createPitcherChartData = (
    predictions,
    recentPerformances,
    playerOdds,
    metric,
  ) => {
    const metricKeyMap = {
      'Innings Pitched': 'inningsPitched',
      Strikeouts: 'strikeoutsThrown',
      'Earned Runs': 'earnedRuns',
      'Hits Allowed': 'hitsAllowed',
      'Walks Issued': 'walksIssued',
    };

    const metricKey = metricKeyMap[metric];

    const sortedPerformances = recentPerformances.sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
    );

    const recentPerformanceValues = sortedPerformances
      .map((p) => parseFloat(p[metricKey]))
      .filter((v) => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return { labels: [], datasets: [] };
    }

    const predictionValue = parseFloat(
      predictions.find((p) => p.metric === metric).value,
    );
    let vegasLine = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKey,
    )?.overUnder;

    if (
      metric === 'Innings Pitched' &&
      playerOdds.some((odd) => odd.metric === 'Outs')
    ) {
      vegasLine = playerOdds.find((odd) => odd.metric === 'Outs').overUnder / 3;
    }

    const vegasLineValue = parseFloat(vegasLine);

    return {
      labels: sortedPerformances.reverse().map((p) => p.gameDate),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues.reverse(),
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data: Array(recentPerformanceValues.length).fill(vegasLineValue),
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const createBatterChartData = (
    predictions,
    recentPerformances,
    playerOdds,
    metric,
  ) => {
    const metricKeyMap = {
      Hits: 'hits',
      'Home Runs': 'home_runs',
    };

    const metricKey = metricKeyMap[metric];

    const sortedPerformances = recentPerformances.sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
    );

    const recentPerformanceValues = sortedPerformances
      .map((p) => {
        const value = parseFloat(p[metricKey]);
        return value;
      })
      .filter((v) => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return { labels: [], datasets: [] };
    }

    const prediction = predictions.find((p) => p.metric === metric);
    if (!prediction) {
      return { labels: [], datasets: [] };
    }
    const predictionValue = parseFloat(prediction.value);

    const vegasOdd = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKey,
    );
    const vegasLine = vegasOdd ? vegasOdd.overUnder : null;
    const vegasLineValue = vegasLine ? parseFloat(vegasLine) : null;

    return {
      labels: sortedPerformances.reverse().map((p) => p.gameDate),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues.reverse(),
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data:
            vegasLineValue !== null
              ? Array(recentPerformances.length).fill(vegasLineValue)
              : [],
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
      },
    },
  };

  const renderTeamMetricsDropdown = (teamName) => {
    const filteredMetrics = teamMetricsData.filter(
      (metric) => metric.team_name === teamName,
    );

    return (
      <div className="metrics-dropdown">
        {filteredMetrics.map((metric, index) => (
          <div key={index}>
            <button
              onClick={() => toggleExpansion(metric.team_name, metric.period)}
              className="period-dropdown"
            >
              {metric.period === 'last_7_days'
                ? 'Last 7'
                : metric.period === 'last_15_days'
                  ? 'Last 15'
                  : metric.period === 'last_30_days'
                    ? 'Last 30'
                    : 'Season'}
            </button>
            {expandedSections[`${metric.team_name}-${metric.period}`] && (
              <div className="nested-dropdown">
                <div className="metric">
                  Average Runs: {metric.average_runs}
                </div>
                <div className="metric">
                  Batting Average: {metric.batting_average}
                </div>
                <div className="metric">
                  Strikeout Rate: {metric.strikeout_rate}
                </div>
                <div className="metric">Walk Rate: {metric.walk_rate}</div>
                <div className="metric">
                  Hits per Inning: {metric.batter_hits_per_inning}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="game-details">
      <div className="header">
        <h1>MLB Game Details for {date}</h1>
        <div>
          <button id="back-button" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
      </div>
      <div id="legend">
        <span className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: 'yellow' }}
          ></span>{' '}
          Recent Performances
        </span>
        <span className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: 'green' }}
          ></span>{' '}
          Prediction Line
        </span>
        <span className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: 'red' }}
          ></span>{' '}
          Vegas Line
        </span>
      </div>
      <div id="game-details-content">
        <div className="game-display-ribbon">
          {selectedGame.game_display_name}
        </div>

        <div className="row centered-content">
          <div className="card">
            <h2>Game Summary & Weather Data</h2>
            <p>
              {awayTeam.name} Projected Total Runs: {awayTeamPoints}
            </p>
            <p>
              {homeTeam.name} Projected Total Runs: {homeTeamPoints}
            </p>
            <p>
              Point Differential:{' '}
              {Math.abs(awayTeamPoints - homeTeamPoints).toFixed(2)} in favor of{' '}
              {awayTeamPoints > homeTeamPoints ? awayTeam.name : homeTeam.name}
            </p>
            <p>Total Points: {totalPoints}</p>
            {weatherInfo && (
              <>
                <br />
                <p>
                  Temperature: {parseFloat(weatherInfo.temperature).toFixed(1)}
                </p>
                <p>Condition: {weatherInfo.weather_conditions}</p>
                <p>Wind: {weatherInfo.wind}</p>
                <p>Precipitation: {weatherInfo.precipitation}</p>
                {domeTeams.has(weatherInfo.game_location) && (
                  <p>This game is played in a dome.</p>
                )}
              </>
            )}
          </div>
        </div>

        <div className="row centered-content">
          <div>
            <h4>{awayTeam.name} Metrics</h4>
            {renderTeamMetricsDropdown(awayTeam.name)}
          </div>
          <div>
            <h4>{homeTeam.name} Metrics</h4>
            {renderTeamMetricsDropdown(homeTeam.name)}
          </div>
        </div>

        {odd_comparison && odd_comparison.length > 0 && (
          <div className="row centered-content">
            <div className="card">
              <h2>Odds Comparison</h2>
              {odd_comparison.map((odds, index) => (
                <div key={index} className="odds-details">
                  <p>Vegas Predicted Total: {odds.vegas_predicted_total}</p>
                  <p>Vegas Predicted Winner: {odds.vegas_predicted_winner}</p>
                  <p>
                    Vegas Predicted Winner Spread:{' '}
                    {odds.vegas_predicted_winner_spread}
                  </p>
                  <p>
                    Pipeline Vegas Total Difference:{' '}
                    {odds.pipeline_vegas_total_diff}
                  </p>
                  <p>
                    Pipeline Vegas Spread Difference:{' '}
                    {odds.pipeline_vegas_spread_diff}
                  </p>
                  <p>Worthy Bet: {odds.worthy_bet}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="team-section">
          <h2 className="team-header">{awayTeam.name} Pitchers</h2>
          <div className="team-details">
            {awayPitchers.map((player) => (
              <div key={player.name} className="player-details">
                <h3>{player.name}</h3>
                {player.opposing_batting_average_against_pitcher && (
                  <div className="additional-details">
                    <p>
                      Opposing Batting Average Against Pitcher:{' '}
                      {player.opposing_batting_average_against_pitcher}
                    </p>
                    <p>
                      Predicted hits based on opposing batting average against
                      pitcher (not final prediction):{' '}
                      {player.predictedHits.toFixed(2)}
                    </p>
                  </div>
                )}
                {player.opposing_strikeout_rate_against_pitcher && (
                  <div className="additional-details">
                    <p>
                      Opposing Strikeout Rate Against Pitcher:{' '}
                      {player.opposing_strikeout_rate_against_pitcher}
                    </p>
                    <p>
                      Predicted strikeouts based on opposing strikeout rate
                      against pitcher (not final prediction):{' '}
                      {player.predictedStrikeouts.toFixed(2)}
                    </p>
                  </div>
                )}
                <div className="predictions">
                  {player.predictions.map((prediction, index) => (
                    <div key={index} className="prediction-card">
                      <h4>{prediction.metric}</h4>
                      <p>{prediction.value}</p>
                      <div className="visualizations">
                        <Line
                          data={createPitcherChartData(
                            player.predictions,
                            player.recentPerformances,
                            mapPlayerOdds(player.name, player_odds),
                            prediction.metric,
                          )}
                          options={chartOptions}
                          width={150}
                          height={150}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="performances">
                    <h4>Player Odds</h4>
                    {mapPlayerOdds(player.name, player_odds).map(
                      (odd, index) => (
                        <p key={index} className="centered-text">
                          Vegas "{odd.metric}" Line: {odd.overUnder}
                        </p>
                      ),
                    )}
                  </div>
                )}
                <div className="performances">
                  <h4>Recent Performances</h4>
                  {player.recentPerformances
                    .sort((a, b) => new Date(b.gameDate) - new Date(a.gameDate))
                    .map((performance, index) => (
                      <p key={index} className="centered-text">
                        {performance.game_display_name}:{' '}
                        {performance.inningsPitched} IP,{' '}
                        {performance.strikeoutsThrown} SO,{' '}
                        {performance.earnedRuns} ER, {performance.hitsAllowed}{' '}
                        H, {performance.walksIssued} BB
                      </p>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="team-section">
          <h2 className="team-header">{homeTeam.name} Pitchers</h2>
          <div className="team-details">
            {homePitchers.map((player) => (
              <div key={player.name} className="player-details">
                <h3>{player.name}</h3>
                {player.opposing_batting_average_against_pitcher && (
                  <div className="additional-details">
                    <p>
                      Opposing Batting Average Against Pitcher:{' '}
                      {player.opposing_batting_average_against_pitcher}
                    </p>
                    <p>
                      Predicted Hits based on Opposing Batting Average:{' '}
                      {player.predictedHits.toFixed(2)}
                    </p>
                  </div>
                )}
                {player.opposing_strikeout_rate_against_pitcher && (
                  <div className="additional-details">
                    <p>
                      Opposing Strikeout Rate Against Pitcher:{' '}
                      {player.opposing_strikeout_rate_against_pitcher}
                    </p>
                    <p>
                      Predicted Strikeouts based on Opposing Strikeout Rate:{' '}
                      {player.predictedStrikeouts.toFixed(2)}
                    </p>
                  </div>
                )}
                <div className="predictions">
                  {player.predictions.map((prediction, index) => (
                    <div key={index} className="prediction-card">
                      <h4>{prediction.metric}</h4>
                      <p>{prediction.value}</p>
                      <div className="visualizations">
                        <Line
                          data={createPitcherChartData(
                            player.predictions,
                            player.recentPerformances,
                            mapPlayerOdds(player.name, player_odds),
                            prediction.metric,
                          )}
                          options={chartOptions}
                          width={150}
                          height={150}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="performances">
                    <h4>Player Odds</h4>
                    {mapPlayerOdds(player.name, player_odds).map(
                      (odd, index) => (
                        <p key={index} className="centered-text">
                          Vegas "{odd.metric}" Line: {odd.overUnder}
                        </p>
                      ),
                    )}
                  </div>
                )}
                <div className="performances">
                  <h4>Recent Performances</h4>
                  {player.recentPerformances
                    .sort((a, b) => new Date(b.gameDate) - new Date(a.gameDate))
                    .map((performance, index) => (
                      <p key={index} className="centered-text">
                        {performance.game_display_name}:{' '}
                        {performance.inningsPitched} IP,{' '}
                        {performance.strikeoutsThrown} SO,{' '}
                        {performance.earnedRuns} ER, {performance.hitsAllowed}{' '}
                        H, {performance.walksIssued} BB
                      </p>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="team-section">
          <h2 className="team-header">{awayTeam.name} Batters</h2>
          <div className="team-details">
            {awayBatters.map((player) => (
              <div key={player.name} className="player-details">
                <h3>{player.name}</h3>
                {player.ab_against_opposing_pitcher > 0 && (
                  <div className="additional-details">
                    <p>
                      AB Against Opposing Pitcher:{' '}
                      {player.ab_against_opposing_pitcher}
                    </p>
                    <p>
                      Hits Against Opposing Pitcher:{' '}
                      {player.hits_against_opposing_pitcher}
                    </p>
                    <p>
                      Batting Average Against Opposing Pitcher:{' '}
                      {player.batting_average_against_opposing_pitcher}
                    </p>
                  </div>
                )}
                <div className="predictions">
                  {player.predictions.map((prediction, index) => (
                    <div key={index} className="prediction-card">
                      <h4>{prediction.metric}</h4>
                      <p>{prediction.value}</p>
                      {['Hits', 'Home Runs', 'Walks'].includes(
                        prediction.metric,
                      ) && (
                        <div className="visualizations">
                          <Line
                            data={createBatterChartData(
                              player.predictions,
                              player.recentPerformances,
                              mapPlayerOdds(player.name, player_odds),
                              prediction.metric,
                            )}
                            options={chartOptions}
                            width={150}
                            height={150}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="performances">
                    <h4>Player Odds</h4>
                    {mapPlayerOdds(player.name, player_odds).map(
                      (odd, index) => (
                        <p key={index} className="centered-text">
                          Vegas "{odd.metric}" Line: {odd.overUnder}
                        </p>
                      ),
                    )}
                  </div>
                )}
                <div className="performances">
                  <h4>Recent Performances</h4>
                  {player.recentPerformances
                    .sort((a, b) => new Date(b.gameDate) - new Date(a.gameDate))
                    .map((performance, index) => (
                      <p key={index} className="centered-text">
                        {performance.game_display_name}: {performance.at_bats}{' '}
                        AB, {performance.hits} H, {performance.home_runs} HR,{' '}
                        {performance.walks} BB, {performance.strikeouts} SO
                      </p>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="team-section">
          <h2 className="team-header">{homeTeam.name} Batters</h2>
          <div className="team-details">
            {homeBatters.map((player) => (
              <div key={player.name} className="player-details">
                <h3>{player.name}</h3>
                {player.ab_against_opposing_pitcher > 0 && (
                  <div className="additional-details">
                    <p>
                      AB Against Opposing Pitcher:{' '}
                      {player.ab_against_opposing_pitcher}
                    </p>
                    <p>
                      Hits Against Opposing Pitcher:{' '}
                      {player.hits_against_opposing_pitcher}
                    </p>
                    <p>
                      Batting Average Against Opposing Pitcher:{' '}
                      {player.batting_average_against_opposing_pitcher}
                    </p>
                  </div>
                )}
                <div className="predictions">
                  {player.predictions.map((prediction, index) => (
                    <div key={index} className="prediction-card">
                      <h4>{prediction.metric}</h4>
                      <p>{prediction.value}</p>
                      {['Hits', 'Home Runs', 'Walks'].includes(
                        prediction.metric,
                      ) && (
                        <div className="visualizations">
                          <Line
                            data={createBatterChartData(
                              player.predictions,
                              player.recentPerformances,
                              mapPlayerOdds(player.name, player_odds),
                              prediction.metric,
                            )}
                            options={chartOptions}
                            width={150}
                            height={150}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="performances">
                    <h4>Player Odds</h4>
                    {mapPlayerOdds(player.name, player_odds).map(
                      (odd, index) => (
                        <p key={index} className="centered-text">
                          Vegas "{odd.metric}" Line: {odd.overUnder}
                        </p>
                      ),
                    )}
                  </div>
                )}
                <div className="performances">
                  <h4>Recent Performances</h4>
                  {player.recentPerformances
                    .sort((a, b) => new Date(b.gameDate) - new Date(a.gameDate))
                    .map((performance, index) => (
                      <p key={index} className="centered-text">
                        {performance.game_display_name}: {performance.at_bats}{' '}
                        AB, {performance.hits} H, {performance.home_runs} HR,{' '}
                        {performance.walks} BB, {performance.strikeouts} SO
                      </p>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MLBGameDetails;
