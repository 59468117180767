import React from 'react';
import { motion } from 'framer-motion';

const Carousel = ({
  header,
  items,
  activeSlide,
  handlePrev,
  handleNext,
  handleClick,
  features = [],
}) => {
  return (
    <div className="relative flex flex-col items-center justify-center space-y-8">
      {/* Carousel Content */}
      <div className="relative flex items-center justify-center w-full max-w-7xl mx-auto px-4 md:px-8 lg:px-12">
        {/* Previous Slide */}
        <button
          className="absolute left-0 sm:-translate-x-1/2 p-4 transition-all duration-300 hover:scale-110 opacity-50 hover:opacity-100 cursor-pointer"
          onClick={handlePrev}
        >
          {items[(activeSlide - 1 + items.length) % items.length]?.content}
        </button>

        {/* Current Slide with Motion Effect */}
        <motion.div
          key={activeSlide}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
          className="z-10 flex flex-col items-center justify-center transform transition-transform duration-700 ease-in-out"
        >
          {items[activeSlide]?.content}
        </motion.div>

        {/* Next Slide */}
        <button
          className="absolute right-0 sm:translate-x-1/2 p-4 transition-all duration-300 hover:scale-110 opacity-50 hover:opacity-100 cursor-pointer"
          onClick={handleNext}
        >
          {items[(activeSlide + 1) % items.length]?.content}
        </button>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center items-center mt-8 space-x-4">
        {items.map((_, index) => (
          <button
            key={index}
            className={`w-5 h-5 rounded-full border-2 transition-all duration-300 ${
              index === activeSlide
                ? 'bg-primary-500 border-primary-700 scale-110 shadow-lg'
                : 'bg-neutral-200 border-neutral-400 hover:scale-110'
            }`}
            onClick={() => handleClick(index)}
          ></button>
        ))}
      </div>

      {/* Features Section - Only render if features exist */}
      {features?.length > 0 && (
        <div className="w-full max-w-6xl bg-white shadow-md p-8 rounded-lg mt-12">
          <h2 className="text-3xl font-heading text-neutral-800 text-center mb-6">
            {`Key Features`}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 text-center">
            {features.map((feature, index) => (
              <div
                key={index}
                className="p-4 rounded-lg bg-black text-white text-lg font-medium shadow-md"
              >
                {feature}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
