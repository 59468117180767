import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ text = '', apiFinished = false }) => {
  const [progress, setProgress] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    let interval;

    if (!apiFinished) {
      interval = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 5 : prev));
      }, 200);
    } else {
      setProgress(100);
    }

    return () => clearInterval(interval);
  }, [apiFinished]);

  useEffect(() => {
    const fadeInterval = setInterval(() => {
      setFade((prev) => !prev);
    }, 1000);

    return () => clearInterval(fadeInterval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {/* Fading Logo */}
      <img
        src="/assets/images/sportbetdata_full_logo.png"
        alt="Logo"
        className={`w-24 md:w-32 lg:w-40 transition-opacity duration-500 ${
          fade ? 'opacity-100' : 'opacity-50'
        }`}
      />

      {/* Loading Text */}
      <p className="text-primary text-3xl font-heading mt-4">{text}</p>

      {/* Progress Bar */}
      <div className="w-full max-w-md mt-6 rounded-lg h-2 overflow-hidden">
        <div
          className="bg-primary h-full transition-all duration-500"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  text: PropTypes.string,
  apiFinished: PropTypes.bool,
};

export default LoadingSpinner;
