import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGoodHistoryBatterData } from '../../../redux/slices/mlbSlice';
import LoadingSpinner from '../../common/LoadingSpinner';

const GoodHistoryCheatSheet = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Select data from Redux store
  const { goodHistoryBatters, isLoading, error } = useSelector(
    (state) => state.mlb,
  );

  useEffect(() => {
    if (date) {
      dispatch(fetchGoodHistoryBatterData(date));
    }
  }, [date, dispatch]);

  if (isLoading) {
    return (
      <LoadingSpinner text="Loading MLB Batter Good History Cheat Sheet..." />
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 font-semibold mt-10">
        {error}
      </div>
    );
  }

  if (!goodHistoryBatters.length) {
    return (
      <div className="text-center text-gray-600 font-medium mt-10">
        No data available for this date.
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-heading text-primary font-bold">
          Batters with Good History Cheat Sheet for {date}
        </h1>
        <button
          onClick={() => navigate(-1)}
          className="bg-primary text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-primary-700 transition duration-300"
        >
          Back
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {goodHistoryBatters.map((player) => (
          <div
            key={player.player_id}
            className="bg-white shadow-md rounded-lg p-5"
          >
            <h2 className="text-xl font-semibold text-neutral-800">
              {player.player_name}
            </h2>
            <div className="mt-2 space-y-2 text-neutral-600">
              <p>
                <span className="font-bold text-neutral-800">
                  Opposing Pitcher:
                </span>{' '}
                {player.opposing_pitcher}
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  Predicted Innings Pitched:
                </span>{' '}
                {player.predicted_innings_pitched}
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  Predicted Hits Allowed:
                </span>{' '}
                {player.predicted_hits_allowed}
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  AB vs Opposing Pitcher:
                </span>{' '}
                {player.ab_against_opposing_pitcher} ABs
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  Hits vs Opposing Pitcher:
                </span>{' '}
                {player.hits_against_opposing_pitcher} Hits
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  HRs vs Opposing Pitcher:
                </span>{' '}
                {player.hrs_against_opposing_pitcher} HRs
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  Total Hits (Last 10):
                </span>{' '}
                {player.hit_amount} Hits
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  Total Home Runs (Last 10):
                </span>{' '}
                {player.home_run_amount} Home Runs
              </p>
              <p>
                <span className="font-bold text-neutral-800">
                  Total AB (Last 10):
                </span>{' '}
                {player.ab_amount} ABs
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoodHistoryCheatSheet;
