/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';

/* 
########################################################################
# Core Logic
########################################################################
*/

function Footer() {
  return (
    <footer className="bg-neutral text-white py-6">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center gap-4">
        {/* Logo or Branding */}
        <div className="flex items-center gap-2">
          <img
            src="/assets/images/sportbetdata_full_logo.png"
            alt="Sport Bet Data Logo"
            className="w-8 h-8"
          />
          <span className="text-xl font-heading">SportBetData.ai</span>
        </div>

        {/* Navigation Links */}
        <div className="flex gap-6">
          <a
            href="/privacy-policy"
            className="text-sm font-light hover:underline transition-all"
          >
            Privacy Policy
          </a>
          <a
            href="/terms-conditions"
            className="text-sm font-light hover:underline transition-all"
          >
            Terms & Conditions
          </a>
        </div>

        {/* Copyright and Social Media */}
        <div className="flex flex-col md:flex-row items-center gap-4">
          <span className="text-sm font-light">© SportBetData.ai | 2024</span>
          <div className="flex gap-4">
            <a
              href="https://twitter.com/sportbetdata"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <img
                src="/assets/icons/misc/twitter.png"
                alt="Twitter"
                className="w-6 h-6"
              />
            </a>
            <a
              href="https://instagram.com/sportbetdata"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-75 transition-opacity"
            >
              <img
                src="/assets/icons/misc/instagram.webp"
                alt="Instagram"
                className="w-6 h-6"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

/* 
########################################################################
# END
########################################################################
*/
