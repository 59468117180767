import axios from 'axios';
import apiConfig from '../config/apiConfig.js';

// Create Axios instance for API client
const apiClient = axios.create({
  baseURL: apiConfig.BASE_URL,
  timeout: 90000,
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (request) => {
    // Inject Authorization header if token exists
    const token = localStorage.getItem('accessToken');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
      }
      if (error.response.status === 403) {
        if (error.response.data.subscriptionStatus === 'expired') {
          console.warn('Subscription expired. Redirecting to dashboard.');
          window.location.href = '/dashboard';
        } else {
          console.error(
            'Access forbidden: You do not have permission to access this resource.',
          );
        }
      }
      if (error.response.status >= 500) {
        console.error('Server error: Please try again later.');
      }
    }

    return Promise.reject(error);
  },
);

// Utility function for dynamically building API endpoints
export const buildApiUrl = (apiType, endpoint) => {
  const apiTypes = {
    user: apiConfig.USER_API,
    subscription: apiConfig.SUBSCRIPTION_API,
    data: apiConfig.DATA_API,
  };

  const apiBase = apiTypes[apiType];
  if (!apiBase) {
    return '';
  }

  return `${apiBase}${endpoint}`;
};

export default apiClient;
