import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  fetchMLBPlayers,
  fetchMLBHRStats,
} from '../../../redux/slices/mlbSlice';
import LoadingSpinner from '../../common/LoadingSpinner';

const HRStats = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get data from Redux store
  const players = useSelector((state) => state.mlb.mlbPlayers);
  const hrStats = useSelector((state) => state.mlb.mlbHRStats);
  const loading = useSelector((state) => state.mlb.isLoading);
  const error = useSelector((state) => state.mlb.error);

  const [selectedPlayers, setSelectedPlayers] = useState([null, null]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    dispatch(fetchMLBPlayers());
  }, [dispatch]);

  const handlePlayerSelection = (selectedOption, index) => {
    const newSelection = [...selectedPlayers];
    newSelection[index] = selectedOption ? selectedOption.value : null;
    setSelectedPlayers(newSelection);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedPlayers.filter((player) => player !== null).length < 2) {
      alert('Please select at least 2 players.');
      return;
    }
    dispatch(fetchMLBHRStats(selectedPlayers.filter((p) => p !== null)));
  };

  const renderStats = () => {
    if (!hrStats) return null;

    return (
      <div className="bg-white p-6 rounded-lg shadow-md border border-neutral-200">
        <h3 className="text-xl font-semibold text-neutral-800 mb-4">
          HR Stats (For days all batters played)
        </h3>
        <p className="text-neutral-700">
          <span className="font-bold">
            Total Days All Selected Players Hit HRs:
          </span>{' '}
          {hrStats.all_hr_days}
        </p>
        <p className="text-neutral-700">
          <span className="font-bold">
            Days None of the Selected Players Hit HRs:
          </span>{' '}
          {hrStats.none_hr_days}
        </p>
        <p className="text-neutral-700">
          <span className="font-bold">Days At Least One Player Hit a HR:</span>{' '}
          {hrStats.single_hr_days}
        </p>

        {hrStats.all_hr_dates && hrStats.all_hr_dates.length > 0 && (
          <div>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="mt-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-700 transition"
            >
              {isExpanded ? 'Hide Details' : 'Show Details'}
            </button>
            {isExpanded && (
              <div className="mt-4 bg-neutral-100 p-4 rounded-lg">
                <h4 className="font-bold text-neutral-800">All HR Dates:</h4>
                <ul className="list-disc list-inside mt-2 text-neutral-600">
                  {hrStats.all_hr_dates.map((date, index) => (
                    <li key={index}>{date}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const playerOptions = players.map((player) => ({
    value: player.player_id,
    label: player.player_name,
  }));

  return (
    <div className="container mx-auto p-4">
      <button
        className="bg-primary text-white px-4 py-2 rounded-lg shadow-md hover:bg-primary-700 transition"
        onClick={() => navigate('/dashboard')}
      >
        Back
      </button>

      <h1 className="text-3xl font-bold text-center text-neutral-800 mt-6">
        MLB Home Run Stats
      </h1>

      <form onSubmit={handleSubmit} className="mt-6 space-y-4">
        <div className="space-y-4">
          {selectedPlayers.map((_, index) => (
            <Select
              key={index}
              options={playerOptions}
              isSearchable
              value={
                playerOptions.find(
                  (option) => option.value === selectedPlayers[index],
                ) || null
              }
              onChange={(selectedOption) =>
                handlePlayerSelection(selectedOption, index)
              }
              placeholder="Select a player"
              className="w-full sm:w-1/2 mx-auto"
            />
          ))}
          <button
            type="button"
            className="block mx-auto bg-secondary text-white px-4 py-2 rounded-lg shadow-md font-semibold hover:bg-secondary-700 transition"
            onClick={() => setSelectedPlayers([...selectedPlayers, null])}
          >
            Add Another Player
          </button>
        </div>

        <button
          type="submit"
          className="block mx-auto bg-primary text-white px-6 py-2 rounded-lg shadow-md font-semibold hover:bg-primary-700 transition"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Get HR Stats'}
        </button>
      </form>

      {loading && <LoadingSpinner text="Fetching HR Stats..." />}
      {error && <div className="text-red-500 text-center mt-4">{error}</div>}

      <div className="mt-8">{renderStats()}</div>
    </div>
  );
};

export default HRStats;
