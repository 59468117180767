import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getNFLPlayerCheatSheet,
  getNFLPropTrends,
  getNFLReverseCorrelations,
  getNFLTDTrends,
  getFilteredNFLPlayerCheatSheet,
  getNFLGameData,
} from '../../api/nflApi';

// **Async Thunk to Fetch NFL Player Cheat Sheet Data**
export const fetchNFLPlayerCheatSheet = createAsyncThunk(
  'nfl/fetchNFLPlayerCheatSheet',
  async (_, { rejectWithValue }) => {
    try {
      return await getNFLPlayerCheatSheet();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Async Thunk to Fetch Prop Trends**
export const fetchNFLPropTrends = createAsyncThunk(
  'nfl/fetchNFLPropTrends',
  async (game_id, { rejectWithValue }) => {
    try {
      return await getNFLPropTrends(game_id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Async Thunk to Fetch Reverse Correlations**
export const fetchNFLReverseCorrelations = createAsyncThunk(
  'nfl/fetchNFLReverseCorrelations',
  async (game_id, { rejectWithValue }) => {
    try {
      return await getNFLReverseCorrelations(game_id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Async Thunk to Fetch TD Trends**
export const fetchNFLTDTrends = createAsyncThunk(
  'nfl/fetchNFLTDTrends',
  async (game_id, { rejectWithValue }) => {
    try {
      return await getNFLTDTrends(game_id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Async Thunk to Fetch Filtered Cheat Sheet Data**
export const fetchFilteredNFLPlayerCheatSheet = createAsyncThunk(
  'nfl/fetchFilteredNFLPlayerCheatSheet',
  async ({ awayTeamName, homeTeamName }, { rejectWithValue }) => {
    try {
      return await getFilteredNFLPlayerCheatSheet(awayTeamName, homeTeamName);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Async Thunk to Fetch NFL Game Data**
export const fetchNFLGameData = createAsyncThunk(
  'nfl/fetchNFLGameData',
  async ({ date, game_id }, { rejectWithValue }) => {
    try {
      return await getNFLGameData(date, game_id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Initial State**
const initialState = {
  playerData: {},
  propTrends: [],
  reverseCorrelations: [],
  tdTrends: [],
  filteredCheatSheet: {},
  gameData: {},
  teamMetrics: {},
  isLoading: false,
  error: null,
  expandedTeams: {},
  expandedPositions: {},
};

// **NFL Slice**
const nflSlice = createSlice({
  name: 'nfl',
  initialState,
  reducers: {
    toggleTeamExpansion(state, action) {
      const team = action.payload;
      state.expandedTeams[team] = !state.expandedTeams[team];
    },
    togglePositionExpansion(state, action) {
      const { team, position } = action.payload;
      if (!state.expandedPositions[team]) {
        state.expandedPositions[team] = {};
      }
      state.expandedPositions[team][position] =
        !state.expandedPositions[team][position];
    },
    clearNFLState(state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      // Player Cheat Sheet
      .addCase(fetchNFLPlayerCheatSheet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNFLPlayerCheatSheet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.playerData = action.payload;
      })
      .addCase(fetchNFLPlayerCheatSheet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Prop Trends
      .addCase(fetchNFLPropTrends.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNFLPropTrends.fulfilled, (state, action) => {
        state.isLoading = false;
        state.propTrends = action.payload;
      })
      .addCase(fetchNFLPropTrends.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Reverse Correlations
      .addCase(fetchNFLReverseCorrelations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNFLReverseCorrelations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reverseCorrelations = action.payload;
      })
      .addCase(fetchNFLReverseCorrelations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // TD Trends
      .addCase(fetchNFLTDTrends.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNFLTDTrends.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tdTrends = action.payload;
      })
      .addCase(fetchNFLTDTrends.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Filtered Cheat Sheet
      .addCase(fetchFilteredNFLPlayerCheatSheet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFilteredNFLPlayerCheatSheet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filteredCheatSheet = action.payload;
      })
      .addCase(fetchFilteredNFLPlayerCheatSheet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Game Data
      .addCase(fetchNFLGameData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNFLGameData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gameData = action.payload.gameData;
        state.teamMetrics = action.payload.teamMetrics;
      })
      .addCase(fetchNFLGameData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// **Export Actions and Reducer**
export const { toggleTeamExpansion, togglePositionExpansion, clearNFLState } =
  nflSlice.actions;
export default nflSlice.reducer;
