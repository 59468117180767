import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ResetPassword = () => {
  const { uid, token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrorMessage('');
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
    try {
      await axios.post(`/reset-password/${uid}/${token}/`, { password });
      setMessage('Password reset successful. Redirecting to login...');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setErrorMessage('Failed to reset password. Please try again.');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-white">
      {/* Header Section */}
      <div className="flex flex-col items-center justify-center flex-grow px-4 sm:px-6">
        {/* Clickable Logo */}
        <div
          className="w-full flex justify-center cursor-pointer mb-8 animate-fadeIn"
          onClick={handleLogoClick}
        >
          <img
            src="/assets/images/sportbetdata_full_logo.png"
            alt="Sport Bet Data Logo"
            className="w-1/2 max-w-xs sm:max-w-md hover:scale-105 transition-transform duration-300"
          />
        </div>

        {/* Reset Password Form */}
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 animate-slideUp mb-16">
          <h2 className="text-2xl font-heading text-neutral mb-4">
            Reset Password
          </h2>
          <form onSubmit={handleResetPassword}>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-primary mb-1"
              >
                New Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-primary mb-1"
              >
                Confirm New Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg font-medium hover:bg-green transition-all duration-300"
            >
              Reset Password
            </button>
          </form>
          {errorMessage && (
            <p className="text-red-500 text-sm text-center mt-4">
              {errorMessage}
            </p>
          )}
          {message && (
            <p className="text-green-500 text-sm text-center mt-4">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
