import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button';

const TermsConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col items-center min-h-screen bg-white/90 px-4 sm:px-6">
      {/* Back Button */}
      <div className="absolute top-4 left-4 sm:top-8 sm:left-8">
        <Button
          onClick={() => navigate(-1)}
          variant="pureBlack"
          size="slimSmall"
          className="border border-white shadow-lg hover:shadow-xl"
        >
          Back
        </Button>
      </div>

      {/* Header Section */}
      <div className="flex flex-col items-center justify-center pt-16 sm:pt-20 pb-6">
        <h2 className="text-4xl font-heading text-neutral mb-4 text-center">
          Terms & Conditions
        </h2>
        <p className="text-gray text-center max-w-2xl">
          Welcome to our website. By browsing and using this website, you agree
          to comply with and be bound by the following terms and conditions.
        </p>
      </div>

      {/* Content Section */}
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-6 md:p-8">
        <h3 className="text-2xl font-heading text-neutral mb-4">
          Use of the Site
        </h3>
        <p className="text-gray mb-6">
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">
          Proprietary Information
        </h3>
        <p className="text-gray mb-6">
          Sports that are subscribed to contain proprietary information meant
          exclusively for subscribers. This information cannot be shared,
          distributed, or reproduced without written consent from
          SportBetData.ai. If you are caught distributing information without
          consent, your subscription can be revoked without notice.
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">
          User Conduct
        </h3>
        <p className="text-gray mb-6">
          Unauthorized use of this website may give rise to a claim for damages
          and/or be a criminal offense.
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">
          Governing Law
        </h3>
        <p className="text-gray mb-6">
          Your use of this website and any dispute arising out of such use of
          the website is subject to the laws of Delaware.
        </p>

        <p className="text-gray">
          Please contact us if you have any questions about our terms and
          conditions.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
