import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../../api/authApi.js';
import Button from '../../common/Button.js';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setMessage('Password reset link has been sent to your email.');
    } catch (error) {
      setMessage('Failed to send password reset link.');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-white">
      {/* Main Content */}
      <div className="flex flex-col items-center justify-center flex-grow px-4">
        <div
          className="w-full flex justify-center cursor-pointer mb-8 animate-fadeIn"
          onClick={handleLogoClick}
        >
          <img
            src="/assets/images/sportbetdata_full_logo.png"
            alt="Sport Bet Data Logo"
            className="w-1/2 max-w-xs md:max-w-md hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 animate-slideUp">
          <h2 className="text-2xl font-heading text-neutral mb-4">
            Forgot Password
          </h2>
          <p className="text-sm text-gray-600 mb-6">
            Enter your email address to reset your password.
          </p>
          <form onSubmit={handleForgotPassword}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-primary mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              size="slimMedium"
              className="w-full"
            >
              Submit
            </Button>
          </form>
          {message && (
            <p className="text-sm text-center mt-4 text-green">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
