import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  children,
  as: Component = 'button',
  onClick,
  variant = 'primary',
  size = 'medium',
  disabled = false,
  fullWidth = false,
  type = 'button',
  className = '',
  ...props
}) => {
  const sizeClasses = {
    xs: 'py-1 px-3 text-xs',
    small: 'py-2 px-4 text-sm',
    medium: 'py-3 px-6 text-base',
    large: 'py-4 px-8 text-lg',
    slimSmall: 'py-1 px-4 text-sm h-8 rounded-full',
    slimMedium: 'py-2 px-6 text-base h-10 rounded-full',
    slimLarge: 'py-3 px-8 text-lg h-12 rounded-full',
  };

  const variantClasses = {
    primary:
      'bg-primary text-white hover:bg-green disabled:bg-gray-300 disabled:cursor-not-allowed',
    secondary:
      'bg-secondary text-white hover:bg-yellow-600 disabled:bg-gray-300 disabled:cursor-not-allowed',
    pureBlack:
      'bg-neutral text-white border border-white hover:bg-black/90 disabled:bg-gray-300 disabled:cursor-not-allowed',
    outlinedPrimary:
      'bg-transparent text-primary border border-primary hover:bg-primary/10 disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed',
    outlinedSecondary:
      'bg-transparent text-secondary border border-secondary hover:bg-secondary/10 disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed',
    danger:
      'bg-danger text-white hover:bg-red disabled:bg-gray-300 disabled:cursor-not-allowed',
  };

  return (
    <Component
      className={`flex items-center justify-center rounded-lg font-medium transition-all duration-300 ${
        sizeClasses[size]
      } ${variantClasses[variant]} ${fullWidth ? 'w-full' : ''} ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={Component === 'button' ? type : undefined}
      {...props}
    >
      {children}
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.elementType,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'pureBlack',
    'outlinedPrimary',
    'outlinedSecondary',
    'danger',
  ]),
  size: PropTypes.oneOf([
    'xs',
    'small',
    'medium',
    'large',
    'slimSmall',
    'slimMedium',
    'slimLarge',
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
};

export default Button;
