import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHRDroughtBatterData } from '../../../redux/slices/mlbSlice';
import LoadingSpinner from '../../common/LoadingSpinner';

const HRDroughtBatterGoodHistoryCheatSheet = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { hrDroughtBatters, gameSummaries, isLoading, error } = useSelector(
    (state) => state.mlb,
  );

  const [expandedGames, setExpandedGames] = useState({});

  useEffect(() => {
    if (date) {
      dispatch(fetchHRDroughtBatterData(date));
    }
  }, [date, dispatch]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({
      ...prev,
      [gameId]: !prev[gameId],
    }));
  };

  // Filter and sort games that have associated drought data
  const filteredAndSortedGames = gameSummaries
    .filter((game) =>
      hrDroughtBatters.some((player) => player.game_id === game.game_id),
    )
    .sort(
      (a, b) =>
        new Date(`1970-01-01T${a.game_time}Z`) -
        new Date(`1970-01-01T${b.game_time}Z`),
    )
    .map((game) => ({
      ...game,
      players: hrDroughtBatters.filter(
        (player) => player.game_id === game.game_id,
      ),
    }));

  if (isLoading) {
    return (
      <LoadingSpinner text="Loading HR Drought with Good Batter History Cheat Sheet..." />
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center mt-10 font-semibold">
        {error}
      </div>
    );
  }

  if (!filteredAndSortedGames.length) {
    return (
      <div className="text-gray-600 text-center mt-10">
        No data available for this date.
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-heading font-bold text-primary">
          HR Drought Batter with Good History Cheat Sheet for {date}
        </h1>
        <button
          onClick={() => navigate(-1)}
          className="bg-primary text-white px-4 py-2 rounded-lg shadow-md text-sm font-semibold hover:bg-primary-700 transition duration-300"
        >
          Back
        </button>
      </div>
      <div className="space-y-6">
        {filteredAndSortedGames.map((game) => (
          <div
            key={game.game_id}
            className="bg-neutral-50 p-6 rounded-lg shadow-md"
          >
            <div
              className="flex justify-between items-center cursor-pointer bg-neutral-700 text-white px-4 py-3 rounded-lg"
              onClick={() => toggleGameExpansion(game.game_id)}
            >
              <span className="font-bold text-lg">
                {game.game_display_name}
              </span>
              <img
                src={
                  expandedGames[game.game_id]
                    ? '/assets/icons/misc/collapse_icon.png'
                    : '/assets/icons/misc/expand_icon.png'
                }
                alt={expandedGames[game.game_id] ? 'Collapse' : 'Expand'}
                className="w-5 h-5"
              />
            </div>
            {expandedGames[game.game_id] && (
              <div className="mt-4 space-y-4">
                {game.players.map((player) => (
                  <div
                    key={player.player_name}
                    className="bg-white p-4 rounded-lg shadow"
                  >
                    <h2 className="text-xl font-semibold text-neutral-800">
                      {player.player_name}
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-neutral-600">
                      <p>
                        <span className="font-bold text-neutral-800">
                          Current HR Drought:
                        </span>{' '}
                        {player.current_drought} games
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Longest HR Drought:
                        </span>{' '}
                        {player.longest_drought} games
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Average HR Drought:
                        </span>{' '}
                        {player.average_drought} games
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Home Runs Against Opposing Pitcher:
                        </span>{' '}
                        {player.hrs_against_opposing_pitcher}
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Hits Against Opposing Pitcher:
                        </span>{' '}
                        {player.hits_against_opposing_pitcher}
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          AB Against Opposing Pitcher:
                        </span>{' '}
                        {player.ab_against_opposing_pitcher}
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Opposing Pitcher:
                        </span>{' '}
                        {player.opposing_pitcher}
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Predicted Earned Runs:
                        </span>{' '}
                        {player.opposing_pitcher_predicted_earned_runs}
                      </p>
                      <p>
                        <span className="font-bold text-neutral-800">
                          Predicted Hits Allowed:
                        </span>{' '}
                        {player.opposing_pitcher_predicted_hits_allowed}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HRDroughtBatterGoodHistoryCheatSheet;
