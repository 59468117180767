import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import faqData from '../misc/faqData.js';
import Button from '../../common/Button';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="relative max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md min-h-screen">
      {/* Back Button */}
      <div className="absolute top-4 left-4 sm:top-8 sm:left-8">
        <Button
          onClick={() => navigate(-1)}
          variant="pureBlack"
          size="slimSmall"
          className="border border-gray-300 shadow-lg hover:shadow-xl"
        >
          Back
        </Button>
      </div>

      {/* FAQ Header */}
      <h2 className="text-2xl font-heading text-neutral text-center mb-6">
        Frequently Asked Questions
      </h2>

      {/* FAQ Items */}
      {Object.entries(faqData).map(([question, answer], index) => (
        <div
          key={index}
          className="faq-item border-b border-gray-300 pb-4 mb-4"
        >
          <div
            className="faq-question flex justify-between items-center p-4 rounded-lg shadow-sm cursor-pointer hover:bg-gray-100 transition"
            onClick={() => toggleAnswer(index)}
          >
            <h3 className="text-lg font-medium text-gray-800">{question}</h3>
            <span className="text-2xl font-bold text-primary">
              {activeIndex === index ? '-' : '+'}
            </span>
          </div>
          {activeIndex === index && (
            <div className="faq-answer mt-2 p-4 rounded-lg text-gray text-sm">
              <p>{answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Faq;
