import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBatterCheatSheetData,
  getGoodHistoryBatterData,
  getHitDroughtBatterGoodHistoryData,
  getHitDroughtData,
  getHotBatterData,
  getHRDroughtBatterData,
  getHRDroughtData,
  getHRDuos,
  getMLBPlayers,
  getMLBHRStats,
  getMLBPitcherPropTrends,
  getPitcherCheatSheetData,
} from '../../api/mlbApi';

// **Async Thunks**

export const fetchBatterCheatSheetData = createAsyncThunk(
  'mlb/fetchBatterCheatSheetData',
  async (date, { rejectWithValue }) => {
    try {
      return await getBatterCheatSheetData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchGoodHistoryBatterData = createAsyncThunk(
  'mlb/fetchGoodHistoryBatterData',
  async (date, { rejectWithValue }) => {
    try {
      return await getGoodHistoryBatterData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchHitDroughtBatterGoodHistoryData = createAsyncThunk(
  'mlb/fetchHitDroughtBatterGoodHistoryData',
  async (date, { rejectWithValue }) => {
    try {
      return await getHitDroughtBatterGoodHistoryData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchHitDroughtData = createAsyncThunk(
  'mlb/fetchHitDroughtData',
  async (date, { rejectWithValue }) => {
    try {
      return await getHitDroughtData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchHotBatterData = createAsyncThunk(
  'mlb/fetchHotBatterData',
  async (date, { rejectWithValue }) => {
    try {
      return await getHotBatterData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchHRDroughtBatterData = createAsyncThunk(
  'mlb/fetchHRDroughtBatterData',
  async (date, { rejectWithValue }) => {
    try {
      return await getHRDroughtBatterData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchHRDroughtData = createAsyncThunk(
  'mlb/fetchHRDroughtData',
  async (date, { rejectWithValue }) => {
    try {
      return await getHRDroughtData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchHRDuos = createAsyncThunk(
  'mlb/fetchHRDuos',
  async (interval, { rejectWithValue }) => {
    try {
      return await getHRDuos(interval);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchMLBPlayers = createAsyncThunk(
  'mlb/fetchMLBPlayers',
  async (_, { rejectWithValue }) => {
    try {
      return await getMLBPlayers();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchMLBHRStats = createAsyncThunk(
  'mlb/fetchMLBHRStats',
  async (playerIds, { rejectWithValue }) => {
    try {
      return await getMLBHRStats(playerIds);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchMLBPitcherPropTrends = createAsyncThunk(
  'mlb/fetchMLBPitcherPropTrends',
  async (_, { rejectWithValue }) => {
    try {
      return await getMLBPitcherPropTrends();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPitcherCheatSheetData = createAsyncThunk(
  'mlb/fetchPitcherCheatSheetData',
  async (date, { rejectWithValue }) => {
    try {
      return await getPitcherCheatSheetData(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Initial State**
const initialState = {
  batterCheatSheet: [],
  goodHistoryBatters: [],
  hitDroughtBatters: { droughtData: [], gameSummaries: [] },
  hotBatters: [],
  hrDroughtBatters: { droughts: [], gameSummaries: [] },
  hrDuos: [],
  mlbPlayers: [],
  mlbHRStats: null,
  pitcherPropTrends: [],
  pitcherCheatSheet: [],
  isLoading: false,
  error: null,
};

// **Slice**
const mlbSlice = createSlice({
  name: 'mlb',
  initialState,
  reducers: {
    clearMLBState(state) {
      state.batterCheatSheet = [];
      state.goodHistoryBatters = [];
      state.hitDroughtBatters = { droughtData: [], gameSummaries: [] };
      state.hotBatters = [];
      state.hrDroughtBatters = { droughts: [], gameSummaries: [] };
      state.hrDuos = [];
      state.mlbPlayers = [];
      state.mlbHRStats = null;
      state.pitcherPropTrends = [];
      state.pitcherCheatSheet = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // **Batter Cheat Sheet**
      .addCase(fetchBatterCheatSheetData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBatterCheatSheetData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.batterCheatSheet = action.payload;
      })
      .addCase(fetchBatterCheatSheetData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **Good History Batter Data**
      .addCase(fetchGoodHistoryBatterData.fulfilled, (state, action) => {
        state.goodHistoryBatters = action.payload;
      })
      .addCase(fetchGoodHistoryBatterData.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **Hit Drought Batter Good History Data**
      .addCase(
        fetchHitDroughtBatterGoodHistoryData.fulfilled,
        (state, action) => {
          state.hitDroughtBatters = action.payload;
        },
      )
      .addCase(
        fetchHitDroughtBatterGoodHistoryData.rejected,
        (state, action) => {
          state.error = action.payload;
        },
      )

      // **Hit Drought Data**
      .addCase(fetchHitDroughtData.fulfilled, (state, action) => {
        state.hitDroughtData = action.payload;
      })
      .addCase(fetchHitDroughtData.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **Hot Batter Data**
      .addCase(fetchHotBatterData.fulfilled, (state, action) => {
        state.hotBatters = action.payload;
      })
      .addCase(fetchHotBatterData.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **HR Drought Batter Data**
      .addCase(fetchHRDroughtBatterData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchHRDroughtBatterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hrDroughtBatters = action.payload.droughts;
        state.gameSummaries = action.payload.gameSummaries;
      })
      .addCase(fetchHRDroughtBatterData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **HR Drought Data**
      .addCase(fetchHRDroughtData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchHRDroughtData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hrDroughtBatters = action.payload.homeRunDroughts;
        state.gameSummaries = action.payload.gameSummaries;
      })
      .addCase(fetchHRDroughtData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **HR Duos Data**
      .addCase(fetchHRDuos.fulfilled, (state, action) => {
        state.hrDuos = action.payload;
      })
      .addCase(fetchHRDuos.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **MLB Players List**
      .addCase(fetchMLBPlayers.fulfilled, (state, action) => {
        state.mlbPlayers = action.payload;
      })
      .addCase(fetchMLBPlayers.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **MLB HR Stats**
      .addCase(fetchMLBHRStats.fulfilled, (state, action) => {
        state.mlbHRStats = action.payload;
      })
      .addCase(fetchMLBHRStats.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **Pitcher Prop Trends**
      .addCase(fetchMLBPitcherPropTrends.fulfilled, (state, action) => {
        state.pitcherPropTrends = action.payload;
      })
      .addCase(fetchMLBPitcherPropTrends.rejected, (state, action) => {
        state.error = action.payload;
      })

      // **Pitcher Cheat Sheet**
      .addCase(fetchPitcherCheatSheetData.fulfilled, (state, action) => {
        state.pitcherCheatSheet = action.payload;
      })
      .addCase(fetchPitcherCheatSheetData.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

// **Export Actions and Reducer**
export const { clearMLBState } = mlbSlice.actions;
export default mlbSlice.reducer;
