import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchHRDuos } from '../../../redux/slices/mlbSlice';
import LoadingSpinner from '../../common/LoadingSpinner';

const intervalOptions = [
  { value: '7', label: 'Last 7 Days' },
  { value: '14', label: 'Last 14 Days' },
  { value: '30', label: 'Last 30 Days' },
  { value: 'season', label: 'Season' },
];

const HRDuos = () => {
  const [interval, setInterval] = useState(intervalOptions[0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Accessing Redux state
  const hrDuos = useSelector((state) => state.mlb.hrDuos);
  const loading = useSelector((state) => state.mlb.isLoading);
  const error = useSelector((state) => state.mlb.error);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchHRDuos(interval.value));
  };

  return (
    <div className="container mx-auto p-4">
      <button
        className="bg-primary text-white px-4 py-2 rounded-lg shadow-md hover:bg-primary-700 transition duration-300"
        onClick={() => navigate(-1)}
      >
        Back
      </button>

      <h1 className="text-2xl font-bold text-center text-neutral-800 mt-6">
        MLB Home Run Duos
      </h1>

      <form
        onSubmit={handleSubmit}
        className="mt-6 flex flex-col sm:flex-row items-center gap-4"
      >
        <Select
          options={intervalOptions}
          value={interval}
          onChange={(selectedOption) => setInterval(selectedOption)}
          className="w-full sm:w-64 text-neutral-800"
          placeholder="Select Interval"
        />
        <button
          type="submit"
          className="bg-secondary text-white px-6 py-2 rounded-lg shadow-md font-semibold hover:bg-secondary-700 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Get HR Duos'}
        </button>
      </form>

      {loading && <LoadingSpinner text="Fetching HR Duos..." />}

      {error && <div className="text-red-500 text-center mt-4">{error}</div>}

      <div className="mt-8 space-y-6">
        {hrDuos.length > 0 ? (
          hrDuos.map((duo, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md border border-neutral-200"
            >
              <h4 className="text-xl font-semibold text-neutral-800">
                {duo.player_combination}
              </h4>
              <p className="text-neutral-600 mt-2">
                <span className="font-bold">Total HR Days:</span>{' '}
                {duo.all_hr_days}
              </p>
              <ul className="mt-2 text-neutral-700">
                {duo.all_hr_dates.map((date, idx) => (
                  <li key={idx} className="text-sm">
                    {date}
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p className="text-neutral-600 text-center mt-4">
            No data available.
          </p>
        )}
      </div>
    </div>
  );
};

export default HRDuos;
