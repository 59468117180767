import apiClient, { buildApiUrl } from './apiClient';
import { handleError } from '../utils/errorHandler';

/**
 * Fetch NFL player cheat sheet data.
 * @returns {Promise<Object>} - Organized cheat sheet data by position.
 * @throws {Error} - If the API call fails.
 */
export const getNFLPlayerCheatSheet = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', 'nfl/cheat-sheets/'),
    );

    // Process response to organize data by teams and positions
    const qbData = response.qb_cheat_sheet || [];
    const rbData = response.rb_cheat_sheet || [];
    const wrData = response.wr_cheat_sheet || [];
    const teData = response.te_cheat_sheet || [];

    const organizedData = {};

    const processData = (data, position) => {
      data.forEach((player) => {
        const team = player.opposing_team;
        if (!organizedData[team]) {
          organizedData[team] = {};
        }
        if (!organizedData[team][position]) {
          organizedData[team][position] = [];
        }
        organizedData[team][position].push(player);
      });
    };

    processData(qbData, 'QB');
    processData(rbData, 'RB');
    processData(wrData, 'WR');
    processData(teData, 'TE');

    return organizedData;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NFL prop trends data.
 * @param {string} game_id - Game ID
 * @returns {Promise<Array>} - Filtered prop trends data
 */
export const getNFLPropTrends = async (game_id) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', 'nfl/prop-trends/'),
    );
    return response.data.filter(
      (trend) => trend.game_id.toString() === game_id,
    );
  } catch (error) {
    handleError(error);
    throw error;
  }
};

/**
 * Fetch NFL reverse correlations data.
 * @param {string} game_id - Game ID
 * @returns {Promise<Array>} - Filtered reverse correlations data
 */
export const getNFLReverseCorrelations = async (game_id) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', 'nfl/reverse-correlations/'),
    );
    return response.data.nfl_reverse_correlations.filter(
      (correlation) => correlation.game_id.toString() === game_id,
    );
  } catch (error) {
    handleError(error);
    throw error;
  }
};

/**
 * Fetch NFL touchdown trends data.
 * @param {string} game_id - Game ID
 * @returns {Promise<Array>} - Filtered touchdown trends data
 */
export const getNFLTDTrends = async (game_id) => {
  try {
    const response = await apiClient.get(buildApiUrl('data', 'nfl/td-trends/'));
    return response.data.td_trends_by_defense_vs_positions.filter(
      (td) => td.game_id.toString() === game_id,
    );
  } catch (error) {
    handleError(error);
    throw error;
  }
};

/**
 * Fetch NFL player cheat sheet filtered by teams.
 * @param {string} awayTeamName - Away team name
 * @param {string} homeTeamName - Home team name
 * @returns {Promise<Object>} - Filtered player cheat sheet data
 */
export const getFilteredNFLPlayerCheatSheet = async (
  awayTeamName,
  homeTeamName,
) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', 'nfl/cheat-sheets/'),
    );

    const qbData = response.data.qb_cheat_sheet.filter(
      (player) =>
        player.opposing_team === awayTeamName ||
        player.opposing_team === homeTeamName,
    );
    const rbData = response.data.rb_cheat_sheet.filter(
      (player) =>
        player.opposing_team === awayTeamName ||
        player.opposing_team === homeTeamName,
    );
    const wrData = response.data.wr_cheat_sheet.filter(
      (player) =>
        player.opposing_team === awayTeamName ||
        player.opposing_team === homeTeamName,
    );
    const teData = response.data.te_cheat_sheet.filter(
      (player) =>
        player.opposing_team === awayTeamName ||
        player.opposing_team === homeTeamName,
    );

    return { qb: qbData, rb: rbData, wr: wrData, te: teData };
  } catch (error) {
    handleError(error);
    throw error;
  }
};

/**
 * Fetch NFL game data and team metrics.
 * @param {string} date - Game date
 * @param {string} game_id - Game ID
 * @returns {Promise<Object>} - Returns game data and team metrics
 */
export const getNFLGameData = async (date, game_id) => {
  try {
    // Fetch game data
    const gameData = await apiClient.get(
      buildApiUrl('data', `nfl/pdf-data/${date}/${game_id}/`),
    );

    // Fetch team metrics data
    const teamMetrics = await apiClient.get(
      buildApiUrl('data', `nfl/team-metrics/${date}/`),
    );

    return {
      gameData,
      teamMetrics,
    };
  } catch (error) {
    handleError(error);
    throw error;
  }
};
