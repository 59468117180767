import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/ReverseCorrelations.css';
import LoadingSpinner from '../../common/LoadingSpinner.js';

const NFLReverseCorrelations = () => {
  const [reverseCorrelations, setReverseCorrelations] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});
  const navigate = useNavigate();

  const handleError = useCallback((error) => {
    if (error.response) {
      setError(`An unexpected error occurred: ${error.response.statusText}`);
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchReverseCorrelationsAndGames = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        // Fetch games
        const gamesResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/current-games/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        const correlationsResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/reverse-correlations/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        setGames(gamesResponse.data);
        setReverseCorrelations(
          correlationsResponse.data.nfl_reverse_correlations,
        );
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchReverseCorrelationsAndGames();
  }, [handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({
      ...prev,
      [gameId]: !prev[gameId],
    }));
  };

  const formatReverseCorrelationBet = (
    metric1,
    metric2,
    odds1,
    odds2,
    predicted1,
    predicted2,
  ) => {
    const bet1 =
      predicted1 > odds1
        ? `Over ${odds1} ${metric1}`
        : `Under ${odds1} ${metric1}`;
    const bet2 =
      predicted2 > odds2
        ? `Over ${odds2} ${metric2}`
        : `Under ${odds2} ${metric2}`;
    return `${bet1} & ${bet2}`;
  };

  if (loading) {
    return <LoadingSpinner text="Loading inverse correlation data..." />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="reverse-correlations-container">
      <div className="header">
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <h1>NFL Potential Inverse Correlations</h1>
      </div>

      <div className="game-list">
        {games.length > 0 ? (
          games.map((game) => (
            <div key={game.game_id} className="game-section">
              <div
                className="game-header"
                onClick={() => toggleGameExpansion(game.game_id)}
              >
                {game.game_display_name}
                <img
                  src={
                    expandedGames[game.game_id]
                      ? '/assets/icons/misc/collapse_icon.png'
                      : '/assets/icons/misc/expand_icon.png'
                  }
                  alt={expandedGames[game.game_id] ? 'Collapse' : 'Expand'}
                  className="expand-collapse-icon"
                />
              </div>
              {expandedGames[game.game_id] && (
                <div className="reverse-correlation-list">
                  {reverseCorrelations
                    .filter(
                      (correlation) =>
                        String(correlation.game_id) === String(game.game_id),
                    )
                    .map((correlation, index) => (
                      <div key={index} className="correlation-card">
                        <div className="player-header">
                          {correlation.player_name}
                        </div>
                        <div className="metric">
                          <span>Metric 1:</span> O/U {correlation.odds1}{' '}
                          {correlation.metric1}
                        </div>
                        <div className="metric">
                          <span>Metric 2:</span> O/U {correlation.odds2}{' '}
                          {correlation.metric2}
                        </div>
                        <div className="metric">
                          <span>Prediction 1:</span> {correlation.predicted1}{' '}
                          {correlation.metric1}
                        </div>
                        <div className="metric">
                          <span>Prediction 2:</span> {correlation.predicted2}{' '}
                          {correlation.metric2}
                        </div>
                        <div className="reverse-correlation-bet">
                          <span>Inverse Correlation Bet:</span>{' '}
                          {formatReverseCorrelationBet(
                            correlation.metric1,
                            correlation.metric2,
                            correlation.odds1,
                            correlation.odds2,
                            correlation.predicted1,
                            correlation.predicted2,
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <div>No games available</div>
        )}
      </div>
    </div>
  );
};

export default NFLReverseCorrelations;
