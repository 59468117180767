import apiClient, { buildApiUrl } from './apiClient';
import { handleError } from '../utils/errorHandler';

/**
 * Check if the user is authenticated by verifying token presence.
 * @returns {boolean} - True if authenticated, otherwise false.
 */
export const isAuthenticated = () => {
  return !!localStorage.getItem('accessToken');
};

/**
 * Refresh the user's access token using the refresh token.
 * @returns {Promise<string>} - The new access token.
 * @throws {Error} - If the token refresh fails.
 */
export const refreshToken = async () => {
  try {
    const response = await apiClient.post(
      buildApiUrl('user', 'token/refresh/'),
      {
        refresh: localStorage.getItem('refreshToken'),
      },
    );

    const { access } = response;
    localStorage.setItem('accessToken', access);
    return access;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Log in the user and store the tokens in local storage.
 * @param {string} email - User's email.
 * @param {string} password - User's password.
 * @returns {Promise<Object>} - Authenticated user data.
 * @throws {Error} - If the login fails.
 */
export const loginUser = async (email, password) => {
  try {
    const response = await apiClient.post(buildApiUrl('user', 'login/'), {
      email,
      password,
    });

    const { access, refresh } = response;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Register a new user.
 * @param {Object} formData - User registration data.
 * @returns {Promise<Object>} - Registered user data.
 * @throws {Error} - If the registration fails.
 */
export const registerUser = async (formData) => {
  try {
    const response = await apiClient.post(
      buildApiUrl('user', 'register/'),
      formData,
    );

    const { access, refresh } = response;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Confirm user's email with a token.
 * @param {string} token - Email confirmation token.
 * @returns {Promise<Object>} - Confirmation response.
 * @throws {Error} - If email confirmation fails.
 */
export const confirmEmail = async (token) => {
  try {
    return await apiClient.post(buildApiUrl('user', 'confirm-email/'), {
      token,
    });
  } catch (error) {
    handleError(error);
  }
};

/**
 * Resend the email verification code.
 * @returns {Promise<Object>} - Response from the server.
 * @throws {Error} - If the request fails.
 */
export const resendEmailCode = async () => {
  try {
    return await apiClient.post(buildApiUrl('user', 'regenerate-tokens/'), {
      regenerate_type: 'email',
    });
  } catch (error) {
    handleError(error);
  }
};

/**
 * Request a password reset email.
 * @param {string} email - The user's email.
 * @returns {Promise<Object>} - Response from the server.
 * @throws {Error} - If the request fails.
 */
export const forgotPassword = async (email) => {
  try {
    return await apiClient.post(buildApiUrl('user', 'regenerate-tokens/'), {
      regenerate_type: 'forgot_password',
      email,
    });
  } catch (error) {
    handleError(error);
  }
};
