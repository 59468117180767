import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { registerUser } from '../../../api/authApi.js';

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    password: '',
    confirm_password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('referral_code');
    if (referralCode) {
      localStorage.setItem('referral_code', referralCode);
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const referral_code = localStorage.getItem('referral_code');
      await registerUser({ ...formData, referral_code });
      navigate('/confirm-email', { state: { email: formData.email } });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-white">
      {/* Header Section */}
      <div className="flex flex-col items-center justify-center flex-grow px-4 sm:px-6">
        {/* Clickable Logo */}
        <div
          className="w-full flex justify-center cursor-pointer mb-8 animate-fadeIn"
          onClick={handleLogoClick}
        >
          <img
            src="/assets/images/sportbetdata_full_logo.png"
            alt="Sport Bet Data Logo"
            className="w-1/2 max-w-xs sm:max-w-md hover:scale-105 transition-transform duration-300"
          />
        </div>

        {/* Registration Form */}
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 animate-slideUp mb-16">
          <h2 className="text-2xl font-heading text-neutral mb-4">Register</h2>
          <form onSubmit={handleRegister}>
            <div className="mb-4">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-primary mb-1"
              >
                First Name
              </label>
              <input
                id="first_name"
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-primary mb-1"
              >
                Last Name
              </label>
              <input
                id="last_name"
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-primary mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="dob"
                className="block text-sm font-medium text-primary mb-1"
              >
                Date of Birth
              </label>
              <input
                id="dob"
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-primary mb-1"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirm_password"
                className="block text-sm font-medium text-primary mb-1"
              >
                Confirm Password
              </label>
              <input
                id="confirm_password"
                type="password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg font-medium hover:bg-green transition-all duration-300"
            >
              Register
            </button>
          </form>
          {errorMessage && (
            <p className="text-red text-sm text-center mt-4">{errorMessage}</p>
          )}
          <div className="text-center mt-6">
            <p className="text-sm mb-2">
              By registering, you agree to our{' '}
              <Link
                to="/privacy-policy"
                target="_blank"
                className="text-blue hover:underline"
              >
                privacy policy
              </Link>{' '}
              and{' '}
              <Link
                to="/terms-conditions"
                target="_blank"
                className="text-blue hover:underline"
              >
                terms & conditions
              </Link>
              .
            </p>
            <p className="text-sm">
              Already have an account?{' '}
              <Link to="/login" className="text-blue hover:underline">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
