import React from 'react';

const StatCard = ({ label, value }) => {
  return (
    <div className="relative w-24 h-24 flex items-center justify-center rounded-full border-4 border-gradient-to-r from-primary-400 via-primary-500 to-primary-600 animate-gradient">
      <div className="text-center">
        <p className="text-xl font-bold text-neutral-800">{value}</p>
        <p className="text-sm text-neutral-600">{label}</p>
      </div>
    </div>
  );
};

export default StatCard;
