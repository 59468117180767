import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNFLPlayerCheatSheet } from '../../../redux/slices/nflSlice';
import LoadingSpinner from '../../common/LoadingSpinner';
import StatCard from '../../common/StatCard';

const PlayerCheatSheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const { playerData, error, isLoading } = useSelector((state) => state.nfl);

  const [expandedTeams, setExpandedTeams] = useState({});
  const [expandedPositions, setExpandedPositions] = useState({});

  // Fetch cheat sheet data on component mount
  useEffect(() => {
    dispatch(fetchNFLPlayerCheatSheet());
  }, [dispatch]);

  // Toggle expansion of team sections
  const toggleTeamExpansion = (team) => {
    setExpandedTeams((prev) => ({
      ...prev,
      [team]: !prev[team],
    }));
  };

  // Toggle expansion of position sections
  const togglePositionExpansion = (team, position) => {
    setExpandedPositions((prev) => ({
      ...prev,
      [team]: {
        ...prev[team],
        [position]: !prev[team]?.[position],
      },
    }));
  };

  // Handle API errors from Redux state
  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  // Show loading spinner while data is being fetched
  if (isLoading || !Object.keys(playerData).length) {
    return <LoadingSpinner text="Loading player data..." />;
  }

  // Sort teams alphabetically
  const sortedTeams = Object.keys(playerData).sort();

  // Render the cheat sheet
  return (
    <div className="min-h-screen px-6 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-heading text-neutral-700">
          Player Cheat Sheet
        </h1>
        <button
          className="bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-600 transition"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>

      {/* Error Handling */}
      {error && <div className="text-red-500 text-center mt-6">{error}</div>}

      {/* Loading State */}
      {isLoading ? (
        <LoadingSpinner text="Loading player data..." />
      ) : (
        <div className="space-y-8">
          {sortedTeams.map((team) => (
            <div key={team} className="bg-white shadow-lg rounded-lg p-6">
              {/* Team Header */}
              <div className="flex justify-between items-center font-bold text-lg text-neutral-800">
                <span>{team} (View Opposing Matchups)</span>
                <button
                  className="text-primary hover:text-primary-600 transition"
                  onClick={() => toggleTeamExpansion(team)}
                >
                  {expandedTeams[team] ? 'Collapse' : 'Expand'}
                </button>
              </div>

              {/* Position List */}
              {expandedTeams[team] && (
                <div className="mt-4 space-y-6">
                  {Object.keys(playerData[team]).map((position) => (
                    <div key={position} className="bg-gray-100 rounded-lg p-6">
                      {/* Position Header */}
                      <div className="flex justify-between items-center font-medium text-neutral-700">
                        <span>{position} (View Players)</span>
                        <button
                          className="text-primary hover:text-primary-600 transition"
                          onClick={() =>
                            togglePositionExpansion(team, position)
                          }
                        >
                          {expandedPositions[team]?.[position]
                            ? 'Collapse'
                            : 'Expand'}
                        </button>
                      </div>

                      {/* Player List */}
                      {expandedPositions[team]?.[position] && (
                        <div className="mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                          {playerData[team][position].map((player) => (
                            <div
                              key={player.player_name}
                              className="bg-white shadow-md rounded-lg p-6"
                            >
                              <div className="font-bold text-neutral-800 text-lg mb-4">
                                {player.player_name} ({position})
                              </div>
                              <div className="flex flex-wrap gap-4 justify-center">
                                {position === 'QB' && (
                                  <>
                                    <StatCard
                                      label="Completions"
                                      value={
                                        player.predicted_passing_completions
                                      }
                                    />
                                    <StatCard
                                      label="Attempts"
                                      value={player.predicted_passing_attempts}
                                    />
                                    <StatCard
                                      label="Passing Yards"
                                      value={player.predicted_passing_yards}
                                    />
                                    <StatCard
                                      label="Passing TDs"
                                      value={player.predicted_passing_tds}
                                    />
                                  </>
                                )}
                                {position === 'RB' && (
                                  <>
                                    <StatCard
                                      label="Rushing Attempts"
                                      value={player.predicted_rushing_carries}
                                    />
                                    <StatCard
                                      label="Rushing Yards"
                                      value={player.predicted_rushing_yards}
                                    />
                                    <StatCard
                                      label="Rushing TDs"
                                      value={player.predicted_rushing_tds}
                                    />
                                    <StatCard
                                      label="Receiving Yards"
                                      value={player.predicted_receiving_yards}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayerCheatSheet;
