import Footer from './Footer';

const ResponsiveWrapper = ({ children }) => {
  return (
    <div className="min-h-screen w-full px-4 sm:px-6 lg:px-8">
      {children}
      {/* Footer - hidden on mobile devices */}
      <div className="hidden sm:block">
        <Footer />
      </div>
    </div>
  );
};

export default ResponsiveWrapper;
