import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FAQ from '../misc/FAQ.js';
import faqData from '../misc/faqData.js';
import Button from '../../common/Button.js';

const Home = () => {
  const [modalImage, setModalImage] = useState(null);
  const [reportImages, setReportImages] = useState([]);

  useEffect(() => {
    const totalImages = 100;
    const tempImages = [];

    // Check for image existence
    const checkImages = async () => {
      for (let i = 1; i <= totalImages; i++) {
        const imgPath = `/assets/reports/pic_${i}.jpg`;
        const imgExists = await imageExists(imgPath);
        if (imgExists) {
          tempImages.push(imgPath);
        } else {
          break;
        }
      }
      setReportImages(tempImages);
    };

    checkImages();
  }, []);

  const imageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-white">
      {/* Header Section */}
      <section className="relative bg-white py-16">
        <div className="flex flex-col items-center justify-center">
          <img
            src="/assets/images/sportbetdata_full_logo.png"
            alt="Sport Bet Data Logo"
            className="z-10 w-3/4 max-w-xs sm:max-w-sm lg:max-w-md mb-8 animate-fadeIn hover:scale-105 transition-transform duration-300"
          />
          <div className="z-10 flex flex-col gap-4 w-full max-w-sm">
            <Button
              as={Link}
              to="/login"
              variant="pureBlack"
              size="slimMedium"
              className="border border-black/50 rounded-md shadow-lg font-heading hover:shadow-xl"
            >
              Login
            </Button>
            <Button
              as={Link}
              to="/register"
              variant="pureBlack"
              size="slimMedium"
              className="border border-black/50 rounded-md shadow-lg font-heading hover:shadow-xl"
            >
              Register
            </Button>
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="w-full h-1 bg-gradient-to-r from-primary to-secondary mb-12"></div>

      {/* Data Derived From App Section */}
      <section className="relative bg-secondary/10 py-16">
        <div className="flex flex-col items-center">
          <h2 className="text-4xl font-heading text-neutral text-center mb-8">
            Data Derived From App
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
            {reportImages.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image}
                  alt={`Report ${index + 1}`}
                  className="rounded-lg shadow-md hover:scale-105 transition-transform duration-300 cursor-pointer"
                  onClick={() => setModalImage(image)}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Modal for Images */}
      {modalImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black/70 z-50"
          onClick={() => setModalImage(null)}
        >
          <div className="relative max-w-4xl w-full p-4 sm:p-6">
            <img
              src={modalImage}
              alt="Modal View"
              className="rounded-lg shadow-lg w-full h-auto max-h-[90vh] object-contain"
            />
            <button
              onClick={() => setModalImage(null)}
              className="absolute top-4 right-4 text-white text-2xl font-bold hover:text-primary transition-colors"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      {/* Divider */}
      <div className="w-full h-1 bg-gradient-to-r from-neutral to-primary mb-12"></div>

      {/* FAQ Section */}
      <section className="relative bg-white py-16">
        <div className="flex flex-col items-center">
          <h2 className="text-4xl font-heading text-neutral text-center mb-8">
            Frequently Asked Questions
          </h2>
          <FAQ faqData={faqData} />
        </div>
      </section>
    </div>
  );
};

export default Home;
