import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col items-center min-h-screen bg-white/90 px-4 sm:px-6">
      {/* Back Button */}
      <div className="absolute top-4 left-4 sm:top-8 sm:left-8">
        <Button
          onClick={() => navigate(-1)}
          variant="pureBlack"
          size="slimSmall"
          className="border border-white shadow-lg hover:shadow-xl"
        >
          Back
        </Button>
      </div>

      {/* Header Section */}
      <div className="flex flex-col items-center justify-center pt-16 sm:pt-20 pb-6">
        <h2 className="text-4xl font-heading text-neutral mb-4 text-center">
          Privacy Policy
        </h2>
        <p className="text-gray text-center max-w-2xl">
          Your privacy is important to us. This policy explains how we handle
          and use your personal information at SportBetData.ai.
        </p>
      </div>

      {/* Content Section */}
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-6 md:p-8">
        <h3 className="text-2xl font-heading text-neutral mb-4">
          Information Collection
        </h3>
        <p className="text-gray mb-6">
          We collect information to provide better services to our users. This
          includes information you provide to us directly, such as when you
          create an account, subscribe to our services, or contact us.
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">
          Use of Information
        </h3>
        <p className="text-gray mb-6">
          The information we collect is used to improve our services, provide
          personalized experiences, and communicate with you effectively. We use
          your information to process transactions, send notifications, provide
          customer support, and analyze how our services are used to enhance
          user experience and service performance.
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">
          Information Sharing
        </h3>
        <p className="text-gray mb-6">
          We do not share personal information with companies, organizations, or
          individuals outside of SportBetData.ai unless one of the following
          circumstances applies:
          <ol className="list-decimal ml-6 mt-2">
            <li>With your consent;</li>
            <li>
              For external processing with trusted partners who comply with our
              privacy policy;
            </li>
            <li>
              For legal reasons, such as to comply with any applicable law,
              regulation, legal process, or enforceable governmental request.
            </li>
          </ol>
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">
          Data Security
        </h3>
        <p className="text-gray mb-6">
          We are committed to protecting your data. We implement various
          security measures to safeguard your personal information from
          unauthorized access, use, or disclosure. These measures include
          encryption, access controls, and secure data storage solutions.
        </p>

        <h3 className="text-2xl font-heading text-neutral mb-4">Your Rights</h3>
        <p className="text-gray mb-6">
          You have the right to access, update, and delete your personal
          information. You can manage your information through your account
          settings or by contacting us directly.
        </p>

        <p className="text-gray">
          Please contact us if you have any questions about our privacy
          practices. We are committed to protecting your privacy and ensuring
          the security of your personal information.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
