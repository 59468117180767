const apiConfig = {
  BASE_URL: process.env.REACT_APP_DJANGO_BASE_URL || 'http://127.0.0.1:8000',
  USER_API: process.env.REACT_APP_USER_API_TYPE || 'user-api/',
  SUBSCRIPTION_API:
    process.env.REACT_APP_SUBSCRIPTION_API_TYPE || 'subscription-api/',
  DATA_API: process.env.REACT_APP_DATA_API_TYPE || 'data-api/',
  STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
};

export default apiConfig;
