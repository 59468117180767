import apiClient, { buildApiUrl } from './apiClient';
import { handleError } from '../utils/errorHandler';

/**
 * Fetch user information.
 * @returns {Promise<Object>} - The user's profile data.
 * @throws {Error} - If the API call fails.
 */
export const getUserInfo = async () => {
  try {
    const response = await apiClient.get(buildApiUrl('user', 'user-info/'));
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch the sports the user is subscribed to.
 * @returns {Promise<Array>} - A list of the user's active subscriptions.
 * @throws {Error} - If the API call fails.
 */
export const getSubscribedSports = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('subscription', 'view-active-user-subscriptions/'),
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Check user's permissions for viewing a sport.
 * @param {string} sportId - The ID of the sport.
 * @returns {Promise<Object>} - Permission data (e.g., can view tomorrow).
 * @throws {Error} - If the API call fails.
 */
export const checkSportPermissions = async (sportId) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `check-sport-permissions/${sportId}/`),
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch sport data including games.
 * @param {string} sportId - The ID of the sport.
 * @param {boolean} includeTomorrow - Whether to include tomorrow's data.
 * @returns {Promise<Array>} - List of games and sport details.
 * @throws {Error} - If the API call fails.
 */
export const getSportData = async (sportId, includeTomorrow = false) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `sports/${sportId}/`),
      {
        params: { include_tomorrow: includeTomorrow },
      },
    );

    return response;
  } catch (error) {
    handleError(error);
  }
};
