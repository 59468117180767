import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/CheatSheet.css';
import LoadingSpinner from '../../common/LoadingSpinner.js';

const DefenseVsPositionTDCheatSheet = () => {
  const navigate = useNavigate();
  const [tdData, setTdData] = useState([]);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});
  const [expandedTeams, setExpandedTeams] = useState({});
  const [loading, setLoading] = useState(true);

  // Handle any API errors
  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    },
    [navigate],
  );

  // Fetch TD trend data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/td-trends/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Access the correct data key from the response
        if (Array.isArray(response.data.td_trends_by_defense_vs_positions)) {
          setTdData(response.data.td_trends_by_defense_vs_positions);
        } else {
          setTdData([]);
        }
        setLoading(false);
      } catch (error) {
        handleError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [handleError]);

  // Toggle expansion of game sections
  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({
      ...prev,
      [gameId]: !prev[gameId],
    }));
  };

  // Toggle expansion of team sections within games
  const toggleTeamExpansion = (team, position) => {
    const key = `${team}-${position}`;
    setExpandedTeams((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Error rendering
  if (error) {
    return <div>{error}</div>;
  }

  // Loading state
  if (loading) {
    return <LoadingSpinner text="Loading defense vs position metrics..." />;
  }

  // Render the cheat sheet only if tdData is an array and not empty
  if (tdData.length === 0) {
    return <div>No data available.</div>;
  }

  // Group the data by game display name
  const groupedByGame = tdData.reduce((acc, data) => {
    if (!acc[data.game_display_name]) {
      acc[data.game_display_name] = [];
    }
    acc[data.game_display_name].push(data);
    return acc;
  }, {});

  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>Defense vs Position TD Cheat Sheet</h1>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div className="game-list">
        {Object.keys(groupedByGame).map((game) => (
          <div key={game} className="game-section">
            <div
              className="game-header"
              onClick={() => toggleGameExpansion(game)}
            >
              {game} (View Trends)
              <img
                src={
                  expandedGames[game]
                    ? '/assets/icons/misc/collapse_icon.png'
                    : '/assets/icons/misc/expand_icon.png'
                }
                alt={expandedGames[game] ? 'Collapse' : 'Expand'}
                className="expand-collapse-icon"
              />
            </div>
            {expandedGames[game] && (
              <div className="team-list">
                {groupedByGame[game].map((td, index) => {
                  const key = `${td.team_name}-${td.position_name}-${index}`;
                  return (
                    <div key={key} className="team-section">
                      <div
                        className="team-header"
                        onClick={() =>
                          toggleTeamExpansion(td.team_name, td.position_name)
                        }
                      >
                        {td.team_name} - {td.position_name}
                        <img
                          src={
                            expandedTeams[`${td.team_name}-${td.position_name}`]
                              ? '/assets/icons/misc/collapse_icon.png'
                              : '/assets/icons/misc/expand_icon.png'
                          }
                          alt={
                            expandedTeams[`${td.team_name}-${td.position_name}`]
                              ? 'Collapse'
                              : 'Expand'
                          }
                          className="expand-collapse-icon"
                        />
                      </div>
                      {expandedTeams[`${td.team_name}-${td.position_name}`] && (
                        <div className="player-details">
                          <div className="metric">Defense: {td.team_name}</div>
                          <div className="metric">
                            Opposing Position: {td.position_name}
                          </div>

                          <br></br>
                          <div className="metric">
                            Games Played: {td.games_played}
                          </div>
                          <br></br>

                          {/* Show Passing TDs Allowed only for Quarterbacks */}
                          {td.position_name === 'Quarterback' && (
                            <>
                              <div className="metric">
                                Passing TDs Allowed: {td.passing_tds_allowed}
                              </div>
                              <div className="metric">
                                Games with 0 Passing TDs:{' '}
                                {td.games_with_0_passing_tds}
                              </div>
                              {td.qb_with_0_passing_tds &&
                                td.qb_with_0_passing_tds.trim() && (
                                  <div className="metric">
                                    QBs with 0 Passing TDs:{' '}
                                    {td.qb_with_0_passing_tds}
                                  </div>
                                )}
                              <div className="metric">
                                Games with 1 Passing TD:{' '}
                                {td.games_with_1_passing_td}
                              </div>
                              <div className="metric">
                                Games with 2+ Passing TDs:{' '}
                                {td.games_with_2plus_passing_tds}
                              </div>
                              {td.qb_with_2_plus_passing_tds &&
                                td.qb_with_2_plus_passing_tds.trim() && (
                                  <div className="metric">
                                    QBs with 2+ Passing TDs:{' '}
                                    {td.qb_with_2_plus_passing_tds}
                                  </div>
                                )}
                            </>
                          )}
                          <br></br>

                          {/* Show ATD (Any Time TDs) for Running Backs */}
                          {td.position_name === 'Running Back' && (
                            <>
                              <div className="metric">
                                ATDs Allowed (Rushing + Receiving TDs):{' '}
                                {td.atd_allowed}
                              </div>
                              {td.rb_with_0_atd && td.rb_with_0_atd.trim() && (
                                <div className="metric">
                                  Notable RBs with 0 ATDs: {td.rb_with_0_atd}
                                </div>
                              )}
                              <div className="metric">
                                Games with 0 ATDs: {td.games_with_0_atd}
                              </div>
                              <div className="metric">
                                Games with 1 ATD: {td.games_with_1_atd}
                              </div>
                              <div className="metric">
                                Games with 2+ ATDs: {td.games_with_2plus_atd}
                              </div>
                              {td.rb_with_2_plus_atd &&
                                td.rb_with_2_plus_atd.trim() && (
                                  <div className="metric">
                                    Notable RBs with 2+ ATDs:{' '}
                                    {td.rb_with_2_plus_atd}
                                  </div>
                                )}
                            </>
                          )}

                          {/* Show ATD (Any Time TDs) for Wide Receivers */}
                          {td.position_name === 'Wide Receiver' && (
                            <>
                              <div className="metric">
                                ATDs Allowed (Rushing + Receiving TDs):{' '}
                                {td.atd_allowed}
                              </div>
                              {td.wr_with_0_atd && td.wr_with_0_atd.trim() && (
                                <div className="metric">
                                  Notable WRs with 0 ATDs: {td.wr_with_0_atd}
                                </div>
                              )}
                              <div className="metric">
                                Games with 0 ATDs: {td.games_with_0_atd}
                              </div>
                              <div className="metric">
                                Games with 1 ATD: {td.games_with_1_atd}
                              </div>
                              <div className="metric">
                                Games with 2+ ATDs: {td.games_with_2plus_atd}
                              </div>
                              {td.wr_with_2_plus_atd &&
                                td.wr_with_2_plus_atd.trim() && (
                                  <div className="metric">
                                    Notable WRs with 2+ ATDs:{' '}
                                    {td.wr_with_2_plus_atd}
                                  </div>
                                )}
                            </>
                          )}

                          {/* Show ATD (Any Time TDs) for Tight Ends */}
                          {td.position_name === 'Tight End' && (
                            <>
                              <div className="metric">
                                ATDs Allowed (Rushing + Receiving TDs):{' '}
                                {td.atd_allowed}
                              </div>
                              {td.te_with_0_atd && td.te_with_0_atd.trim() && (
                                <div className="metric">
                                  Notable TEs with 0 ATDs: {td.te_with_0_atd}
                                </div>
                              )}
                              <div className="metric">
                                Games with 0 ATDs: {td.games_with_0_atd}
                              </div>
                              <div className="metric">
                                Games with 1 ATD: {td.games_with_1_atd}
                              </div>
                              <div className="metric">
                                Games with 2+ ATDs: {td.games_with_2plus_atd}
                              </div>
                              {td.te_with_2_plus_atd &&
                                td.te_with_2_plus_atd.trim() && (
                                  <div className="metric">
                                    Notable TEs with 2+ ATDs:{' '}
                                    {td.te_with_2_plus_atd}
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DefenseVsPositionTDCheatSheet;
