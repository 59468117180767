import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex h-screen bg-neutral-50">
      {/* Main Content */}
      <div className="flex-1 flex flex-col justify-center items-center text-center px-6">
        <h1 className="text-6xl sm:text-8xl md:text-9xl font-heading text-primary-500 animate-fadeUp mb-4">
          404
        </h1>
        <p className="text-lg md:text-xl font-body text-neutral-500 mb-6 animate-fadeIn">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="px-8 py-3 bg-primary-500 text-white rounded-full text-base font-medium hover:bg-primary-600 transition-shadow shadow-md hover:shadow-lg animate-slideIn"
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
