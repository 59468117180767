/* 
# ##################################################################################################
# Imports
# ##################################################################################################
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '../../common/Button.js';
import LoadingSpinner from '../../common/LoadingSpinner.js';

/* 
# ##################################################################################################
# Core Logic
# ##################################################################################################
*/

const FileIssue = () => {
  const [issueType, setIssueType] = useState('');
  const [issueDetail, setIssueDetail] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/login');
      } else {
        try {
          await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}check-auth/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setLoading(false);
        } catch (error) {
          navigate('/login');
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  const handleFileChange = (e) => {
    setAttachments(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('issue_type', issueType);
    formData.append('issue_detail', issueDetail);

    Array.from(attachments).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const token = localStorage.getItem('accessToken');

    try {
      await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}file-issue/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMessage('Issue submitted successfully.');
    } catch (error) {
      setMessage('Failed to submit issue.');
    }
  };

  if (loading) {
    return <LoadingSpinner text="Loading file issue form..." />;
  }

  return (
    <div className="relative min-h-screen">
      {/* Back Button */}
      <div className="absolute top-4 left-4 sm:top-8 sm:left-8">
        <Button
          onClick={() => navigate(-1)}
          variant="pureBlack"
          size="slimSmall"
          className="border border-gray-300 shadow-lg hover:shadow-xl"
        >
          Back
        </Button>
      </div>

      {/* Content */}
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-20">
        <h1 className="text-3xl font-heading text-neutral text-center mb-8">
          File an Issue
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Issue Type */}
          <div>
            <label
              htmlFor="issueType"
              className="block text-gray-700 font-medium mb-2"
            >
              Issue Type
            </label>
            <select
              id="issueType"
              value={issueType}
              onChange={(e) => setIssueType(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-lg p-2"
            >
              <option value="">Select an issue</option>
              <option value="subscription">Subscription Issue</option>
              <option value="payment">Payment Issue</option>
              <option value="technical">Technical Issue</option>
              <option value="other">Other</option>
            </select>
          </div>

          {/* Issue Detail */}
          <div>
            <label
              htmlFor="issueDetail"
              className="block text-gray-700 font-medium mb-2"
            >
              Issue Detail
            </label>
            <textarea
              id="issueDetail"
              value={issueDetail}
              onChange={(e) => setIssueDetail(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-lg p-2 h-32 resize-none"
            />
          </div>

          {/* Attachments */}
          <div>
            <label
              htmlFor="attachments"
              className="block text-gray-700 font-medium mb-2"
            >
              Attachments (optional)
            </label>
            <input
              type="file"
              id="attachments"
              multiple
              onChange={handleFileChange}
              className="w-full border border-gray-300 rounded-lg p-2"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 rounded-lg hover:bg-primary-dark"
          >
            Submit
          </button>

          {/* Message */}
          {message && (
            <p
              className={`mt-4 text-center ${message.includes('success') ? 'text-green-600' : 'text-red-600'}`}
            >
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default FileIssue;

/* 
# ##################################################################################################
# END
# ##################################################################################################
*/
